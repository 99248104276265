define("ember-route-helpers/utils/handle-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(params) {
    let possibleQueryParams = params[params.length - 1];
    if (possibleQueryParams && possibleQueryParams.isQueryParams) {
      // ensure to handle potentially frozen arrays
      params = params.slice();
      params[params.length - 1] = {
        queryParams: possibleQueryParams.values
      };
    }
    return params;
  }
});