define("@woody-lite/core-session/components/modal-safe-tap-error", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/object", "@woody-lite/core-session/constants", "@woody-lite/core-session/errors/user-choose-signature-method", "@woody-lite/core-session/errors/safetap-technical", "@woody-lite/core-session/templates/modal-safe-tap-error"], function (_exports, _modal, _object, _constants, _userChooseSignatureMethod, _safetapTechnical, _modalSafeTapError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const ERRORS = {
    invalidCard: {
      title: 'label.safeTap.errors.invalidCard.title',
      text1: 'label.safeTap.errors.invalidCard.text1',
      text2: 'label.safeTap.errors.invalidCard.text2'
    },
    technical: {
      title: 'label.safeTap.errors.technical.title',
      text1: 'label.safeTap.errors.technical.text1'
    },
    reading: {
      title: 'label.safeTap.errors.reading.title',
      text1: 'label.safeTap.errors.reading.text1',
      text2: 'label.safeTap.errors.reading.text2'
    }
  };
  let ModalSafeTapErrorComponent = _exports.default = (_class = class ModalSafeTapErrorComponent extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _modalSafeTapError.default);
      _defineProperty(this, "errorType", _constants.SAFETAP_ERROR_TYPES.TECHNICAL_ERROR);
    }
    get showLink() {
      return this.errorType === _constants.SAFETAP_ERROR_TYPES.INVALID_CARD_ERROR;
    }
    get showAnotherMethod() {
      return this.errorType === _constants.SAFETAP_ERROR_TYPES.TECHNICAL_ERROR;
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let {
        errorType
      } = this.model.options;
      errorType = ERRORS[errorType] ? errorType : _constants.SAFETAP_ERROR_TYPES.TECHNICAL_ERROR;
      this.errorType = errorType;
      this.title = ERRORS[this.errorType].title;
      this.text1 = ERRORS[this.errorType].text1;
      this.text2 = ERRORS[this.errorType].text2;
    }
    closeModal() {
      if (this.errorType === _constants.SAFETAP_ERROR_TYPES.TECHNICAL_ERROR) {
        return this.reject(new _safetapTechnical.SafeTapTechnicalError());
      }
      return this.reject();
    }
    openValidCards() {
      this.modal.open('card-list');
    }
    chooseAnotherMethod() {
      this.reject(new _userChooseSignatureMethod.UserChooseSignatureMethodError());
    }
  }, _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openValidCards", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openValidCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseAnotherMethod", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "chooseAnotherMethod"), _class.prototype), _class);
});