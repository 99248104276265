define("@woody-lite/addon-utils/helpers/validators/has-correlation", ["exports", "@ember/component/helper", "@ember/service", "@ember/object"], function (_exports, _helper, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasCorrelation = hasCorrelation;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function hasCorrelation(key, maxCorr) {
    let result = false;
    let correlativesSum = 1;
    let correlativesSubtract = 1;
    let pos = 0;
    while (pos < key.length - 1 && result === false) {
      const currentPos = key.charAt(pos);
      const currentCharCode = currentPos.charCodeAt(0);
      const nextPos = key.charAt(pos + 1);
      const nextCharCode = nextPos.charCodeAt(0);
      if (currentCharCode === nextCharCode - 1) {
        correlativesSum++;
        correlativesSubtract = 1;
      } else if (currentCharCode === nextCharCode + 1) {
        correlativesSubtract++;
        correlativesSum = 1;
      } else {
        correlativesSum = 1;
        correlativesSubtract = 1;
      }
      pos++;
      if (correlativesSum > maxCorr || correlativesSubtract > maxCorr) {
        result = true;
      }
    }
    return result;
  }
  let HasCorrelationHelper = _exports.default = (_class = class HasCorrelationHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    compute(_ref, _ref2) {
      let [max] = _ref;
      let {
        message
      } = _ref2;
      return {
        message: message || this.intl.t('error.hasCorrelation', {
          max
        }),
        validate(component) {
          const value = ((0, _object.get)(component, '_value') || '').toLowerCase();
          return hasCorrelation(value, max);
        }
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});