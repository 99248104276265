define("ember-collector-dispatcher/storage-adapters/local-storage", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LocalStorage extends _object.default {
    init() {
      super.init(...arguments);
      if (!this.key) {
        throw new Error('LocalStorage storage adapter needs a key');
      }
      this.db = window.localStorage;
    }
    async isSupported() {
      try {
        this.db.setItem('supported', '0');
        this.db.removeItem('supported');
      } catch (e) {
        return false;
      }
      return true;
    }
    async setItems(items) {
      this.db.setItem(this.key, JSON.stringify(items));
    }
    getItems() {
      const storage = this.db.getItem(this.key);
      return storage ? JSON.parse(storage) : [];
    }
    async count() {
      return this.getItems().length;
    }
    async push() {
      const storedItems = this.getItems();
      for (var _len = arguments.length, items = new Array(_len), _key = 0; _key < _len; _key++) {
        items[_key] = arguments[_key];
      }
      items.forEach(item => {
        storedItems.push(item);
      });
      await this.setItems(storedItems);
    }
    async unshift() {
      const storedItems = this.getItems();
      for (var _len2 = arguments.length, items = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        items[_key2] = arguments[_key2];
      }
      items.forEach(item => {
        storedItems.unshift(item);
      });
      await this.setItems(storedItems);
    }
    async pop() {
      let count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      const storedItems = this.getItems();
      const items = storedItems.splice(-count);
      await this.setItems(storedItems);
      return items;
    }
    async shift() {
      let count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      const storedItems = this.getItems();
      const items = storedItems.splice(0, count);
      await this.setItems(storedItems);
      return items;
    }
  }
  _exports.default = LocalStorage;
});