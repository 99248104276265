define("@woody-lite/core-traceability/utils/is-adapter-in-dryRun", ["exports", "@woody-lite/core-traceability/utils/get-adapter-config"], function (_exports, _getAdapterConfig2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isAdapterInDryRun;
  function isAdapterInDryRun(config) {
    var _getAdapterConfig;
    let adapterName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'tealium';
    const tealiumAdapterConfig = (_getAdapterConfig = (0, _getAdapterConfig2.default)(config, adapterName)) === null || _getAdapterConfig === void 0 ? void 0 : _getAdapterConfig.config;
    const addonConfig = config === null || config === void 0 ? void 0 : config['core-traceability'];
    if (!tealiumAdapterConfig || !addonConfig) {
      return true;
    }
    return !addonConfig.scriptUrl || tealiumAdapterConfig.dryRun;
  }
});