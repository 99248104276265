define("ember-collector-dispatcher/services/collector", ["exports", "@ember/service", "@ember/application"], function (_exports, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Collector extends _service.default {
    async setup() {
      if (!this.adapters) {
        throw new Error('You must define `adapters` property on your configuration');
      }
      const supportedAdapter = await this.getAdapter();
      if (!supportedAdapter) {
        throw new Error('You must define any supported adapter: indexed-db, local-storage or memory');
      }
      this.storageAdapter = supportedAdapter;
    }
    async getAdapter() {
      const owner = (0, _application.getOwner)(this);
      let supported;
      for (let i = 0; i < this.adapters.length; i++) {
        let current = this.adapters[i];
        let options;
        if (Array.isArray(current)) {
          options = current[1];
          current = current[0];
        }
        const adapter = owner.factoryFor("storage-adapter:".concat(current)).create(options);
        if (await adapter.isSupported()) {
          supported = adapter;
          break;
        }
        adapter.destroy();
      }
      return supported;
    }
    async count() {
      if (!this.storageAdapter) {
        await this.setup();
      }
      return this.storageAdapter.count();
    }
    async push() {
      if (!this.storageAdapter) {
        await this.setup();
      }
      return this.storageAdapter.push(...arguments);
    }
    async unshift() {
      if (!this.storageAdapter) {
        await this.setup();
      }
      return this.storageAdapter.unshift(...arguments);
    }
    async pop(count) {
      if (!this.storageAdapter) {
        await this.setup();
      }
      return this.storageAdapter.pop(count);
    }
    async shift(count) {
      if (!this.storageAdapter) {
        await this.setup();
      }
      return this.storageAdapter.shift(count);
    }
  }
  _exports.default = Collector;
});