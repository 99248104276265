define("liquid-fire/templates/components/liquid-versions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "nZH7xxO7",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"versions\"]]],null]],null],null,[[[41,[28,[37,3],[[30,0,[\"renderWhenFalse\"]],[30,1,[\"value\"]]],null],[[[6,[39,4],null,[[\"version\",\"liquidChildDidRender\",\"class\"],[[30,1],[28,[37,5],[[30,0],\"childDidRender\"],null],[30,0,[\"class\"]]]],[[\"default\"],[[[[18,2,[[30,1,[\"value\"]]]]],[]]]]]],[]],null]],[1]],null]],[\"version\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"lf-or\",\"liquid-child\",\"action\",\"yield\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-versions.hbs",
    "isStrictMode": false
  });
});