define("@woody-lite/core-session/repositories/biometrics-config", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/repositories/repository", "@ember/service", "@ember/object", "@woody-lite/core-session/constants"], function (_exports, _repository, _service, _object, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const MAX_TIMES_SHOW = 3;
  const STORAGE_NAME = 'firstExperienceAccess';
  let ConfigurationRepository = _exports.default = (_class = class ConfigurationRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "userStorage", _descriptor, this);
    }
    async getBiometricsConfig() {
      const {
        count,
        lastShown
      } = await this._getCountAndLastShown();
      const {
        canEnrollDevice,
        methodToEnroll
      } = await this._canEnrollDevice();
      const customer = await this.provider.pull('customer', 'getContextualData');
      const hasEnrolledWithBiometry = _constants.ACCESS_METHODS[(0, _object.get)(customer, 'status.accessType')];
      const shouldEnrollBiometrics = !hasEnrolledWithBiometry && count < MAX_TIMES_SHOW && canEnrollDevice && methodToEnroll.length > 0;
      return this.createEntry('biometrics-config', 'BIOMETRICS_CONFIGURATION', {
        count,
        lastShown,
        shouldEnrollBiometrics,
        methodToEnroll
      });
    }
    async saveBiometricsConfig(biometricsConfigurationModel) {
      await this.userStorage.setItem(STORAGE_NAME, biometricsConfigurationModel.toJSON());
      biometricsConfigurationModel.sync();
    }
    async _canEnrollDevice() {
      const accessMethods = await this.callRepository('access-method', 'findAll');
      const biometricMethods = accessMethods.filter(item => !(0, _object.get)(item, 'isConsultive'));
      const enabledMethod = biometricMethods === null || biometricMethods === void 0 ? void 0 : biometricMethods.find(item => (0, _object.get)(item, 'isEnabled'));
      const accessMethod = biometricMethods === null || biometricMethods === void 0 ? void 0 : biometricMethods.filter(item => (0, _object.get)(item, 'isSupported'));
      const canEnrollDevice = !enabledMethod;
      return {
        canEnrollDevice,
        methodToEnroll: accessMethod
      };
    }
    async _getCountAndLastShown() {
      const conditionsBiometricModal = await this.userStorage.getItem(STORAGE_NAME);
      if (!conditionsBiometricModal) {
        return {
          count: 0,
          lastShown: new Date().getTime()
        };
      }
      const lastShown = conditionsBiometricModal.lastShown;
      const today = new Date();
      const lastShownDate = new Date(lastShown);
      const lastShownPlusOneMonth = lastShownDate.setMonth(lastShownDate.getMonth() + 1);
      const resetCount = today.getTime() > lastShownPlusOneMonth;
      if (resetCount) {
        return {
          count: 0,
          lastShown: new Date().getTime()
        };
      }
      return {
        count: conditionsBiometricModal.count,
        lastShown
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "userStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});