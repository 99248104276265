define("@woody-lite/core-session/models/configuration", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/models/base", "ember-utils/utils/primitive/merge", "@ember/object"], function (_exports, _base, _merge, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function getLocations(obj) {
    const locations = {};

    // Loop over object keys
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (value.isActive && value.relativeUrl && value.id) {
        locations[value.id] = value.relativeUrl;
      } else if (Array.isArray(value)) {
        (0, _merge.default)(locations, getLocations(value));
      }
    });
    return locations;
  }
  let ConfigurationModel = _exports.default = (_dec = (0, _object.computed)('content'), _class = class ConfigurationModel extends _base.default {
    /**
     * An object that stores route names and its route path:
     * ```js
     * {
     *   'some-route': 'some.ember.route.path'
     * }
     * ```
     *
     * @type {Object}
     */
    get locations() {
      return getLocations(this.content);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "locations", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "locations"), _class.prototype), _class);
});