define("@woody-lite/engine-my-mailbox/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('message-detail', {
      path: '/message/:message_id'
    });
    this.route('messages', {
      path: '/category/:category_id'
    });
    this.route('announcement-detail', {
      path: 'announcement-detail/:kife_task_id'
    });
    this.route('messages-search-result');
    this.route('notifications');
    this.route('tasks');
  });
});