define("@woody-lite/addon-conversations/normalizers/thread-message-detail", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThreadMessageDetailNormalizer extends _object.default {
    normalize(properties) {
      properties.text = properties.content; // fix object proxy

      delete properties.content;
      return {
        name: 'thread-message-detail',
        properties
      };
    }
  }
  _exports.default = ThreadMessageDetailNormalizer;
});