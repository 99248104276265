define("@woody-lite/addon-tasks/errors/task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TaskError extends Error {
    constructor(_ref) {
      let {
        errorSeverity,
        errorLabel
      } = _ref;
      super('Problem with task acceptation');
      this.name = 'TaskError';
      this.errorSeverity = errorSeverity;
      this.errorLabel = errorLabel;
    }
  }
  _exports.default = TaskError;
});