define("@woody-lite/core-session/decorators/headers-bbva", ["exports", "@ember/service", "@ember/object", "@ember/application", "@woody-lite/core-session/utils/headers-bbva", "@ember/debug"], function (_exports, _service, _object, _application, _headersBbva, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = headersBBVA;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const ENAX = 'enax';
  const GAM = 'gam';
  const ALLOWED_TYPES = [ENAX, GAM];
  function headersBBVA(type) {
    (false && !(ALLOWED_TYPES.includes(type)) && (0, _debug.assert)('Only is allowed gam and enax types', ALLOWED_TYPES.includes(type)));
    return function (target) {
      var _class, _descriptor;
      const klass = (_class = class _class extends target {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "provider", _descriptor, this);
        }
        get device() {
          return this.provider.pull('device', 'getDevice');
        }
        get application() {
          return this._getApplication();
        }
        async _getApplication() {
          const device = await this.device;
          if (device.isNative) {
            return this.provider.pull('application', 'getNativeApplication');
          }
          return this.provider.pull('application', 'getApplication');
        }
        async before() {
          var _this$headers;
          const application = await this.application;
          const device = await this.device;
          const deviceId = (0, _object.get)(device, 'id');
          const environment = (0, _application.getOwner)(this).resolveRegistration('config:environment').APP.BBVA_ENV;
          const enaxHeaders = type === ENAX ? {
            'Accept-Language': (0, _object.get)(device, 'language'),
            ContactId: (_this$headers = this.headers) === null || _this$headers === void 0 ? void 0 : _this$headers.ContactId,
            'thirdparty-deviceid': deviceId
          } : {};
          this.headers = Object.assign({
            'Content-Language': (0, _object.get)(device, 'language'),
            'BBVA-User-Agent': (0, _headersBbva.getBBVAUserAgent)(application, device, environment)
          }, enaxHeaders, this.headers);
          return super.before(...arguments);
        }
      }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _class);
      Object.defineProperty(klass, 'name', {
        value: target.name
      });
      return klass;
    };
  }
});