define("@woody-mrs-potato/addon-slinky/utils/event-emitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class EventEmitter {
    constructor() {
      _defineProperty(this, "events", {});
    }
    on(event, listener) {
      if (typeof this.events[event] !== 'object') {
        this.events[event] = [];
      }
      this.events[event].push(listener);
    }
    addListener(event, listener) {
      this.on(event, listener);
    }
    removeListener(event, listener) {
      if (typeof this.events[event] !== 'object') {
        return;
      }
      const idx = this.events[event].indexOf(listener);
      if (idx > -1) {
        this.events[event].splice(idx, 1);
      }
    }
    removeAllListeners(eventName) {
      if (eventName) {
        delete this.events[eventName];
      } else {
        this.events = {};
      }
    }
    emit(event) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      if (typeof this.events[event] !== 'object') {
        return;
      }
      this.events[event].forEach(listener => listener.apply(this, args));
    }
    once(event, listener) {
      var _this = this;
      const wrapper = function () {
        listener(...arguments);
        _this.removeListener(event, wrapper);
      };
      this.on(event, wrapper);
    }
    listenerCount(eventName) {
      var _this$events$eventNam;
      return ((_this$events$eventNam = this.events[eventName]) === null || _this$events$eventNam === void 0 ? void 0 : _this$events$eventNam.length) || 0;
    }
  }
  _exports.default = EventEmitter;
});