define("@woody-lite/core-session/utils/headers-bbva", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBBVAUserAgent = void 0;
  const browserName = device => {
    const deviceBrowserName = (0, _object.get)(device, 'browser.name');
    const validBrowser = deviceBrowserName === null || deviceBrowserName === void 0 ? void 0 : deviceBrowserName.match(/(firefox|msie|chrome|safari|webkit)/gi);
    return validBrowser === null || validBrowser === void 0 ? void 0 : validBrowser[0];
  };
  const browserVersion = device => (0, _object.get)(device, 'browser.version');
  const getBBVAUserAgent = (application, device, environment) => [(0, _object.get)(device, 'id'), (0, _object.get)(device, 'isNative') ? (0, _object.get)(device, 'platform') : 'HTML', (0, _object.get)(device, 'vendor'), (0, _object.get)(device, 'model'), (0, _object.get)(device, 'screen.resolution'), (0, _object.get)(device, 'operatingSystem.name'), (0, _object.get)(device, 'operatingSystem.version'), (0, _object.get)(application, 'name'), (0, _object.get)(application, 'version'), (0, _object.get)(device, 'screen.scale'), '',
  // webview version,
  environment, '',
  // preinstalled Xiaomi
  browserName(device), browserVersion(device)].join(';');
  _exports.getBBVAUserAgent = getBBVAUserAgent;
});