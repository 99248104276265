define("@woody-mrs-potato/addon-slinky/errors/timeout-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TimeoutExpiredError extends Error {
    constructor(message) {
      super(message);
      this.name = 'TimeoutExpiredError';
    }
  }
  _exports.default = TimeoutExpiredError;
});