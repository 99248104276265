define("@woody-lite/addon-crm-campaigns/models/enax-action", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRIGGERS = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const TRIGGERS = _exports.TRIGGERS = {
    OK: 'ok',
    CANCEL: 'cancel',
    LOAD: 'load',
    PROCESS_COMPLETED: 'processCompleted',
    PROCESS_ABANDONED: 'processAbandoned'
  };
  const ACTIONS = {
    [TRIGGERS.OK]: {
      eventType: 'INTERESTED',
      dismissAfterAction: false,
      trace: false
    },
    [TRIGGERS.CANCEL]: {
      eventType: 'REJECTED',
      dismissAfterAction: true,
      trace: true
    },
    [TRIGGERS.LOAD]: {
      eventType: 'DISPLAYED',
      dismissAfterAction: false,
      trace: false
    },
    [TRIGGERS.PROCESS_COMPLETED]: {
      eventType: 'ACQUIRED',
      dismissAfterAction: true,
      trace: false
    },
    [TRIGGERS.PROCESS_ABANDONED]: {
      eventType: 'ABANDONED',
      dismissAfterAction: false,
      trace: false
    }
  };
  class EnaxActionModel extends _object.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "trigger", null);
      _defineProperty(this, "campaignId", null);
      _defineProperty(this, "channel", null);
    }
    /**
     * When this actions should be triggered:
     *   - `load`: when the user see the campaign
     *   - `ok`: when the user interacts with the campaign
     *   - `cancel`: when the user dismiss the campaign
     *   - `processCompleted`: when the user completes the operative
     *   - `processAbandoned`: when the user exits the operative
     */
    get dismissAfterAction() {
      var _ACTIONS$this$trigger;
      return (_ACTIONS$this$trigger = ACTIONS[this.trigger]) === null || _ACTIONS$this$trigger === void 0 ? void 0 : _ACTIONS$this$trigger.dismissAfterAction;
    }
    get trace() {
      var _ACTIONS$this$trigger2;
      return (_ACTIONS$this$trigger2 = ACTIONS[this.trigger]) === null || _ACTIONS$this$trigger2 === void 0 ? void 0 : _ACTIONS$this$trigger2.trace;
    }
    get eventType() {
      var _ACTIONS$this$trigger3;
      return (_ACTIONS$this$trigger3 = ACTIONS[this.trigger]) === null || _ACTIONS$this$trigger3 === void 0 ? void 0 : _ACTIONS$this$trigger3.eventType;
    }
    get inAppScreenData() {
      return {
        campaignId: this.campaignId,
        eventType: this.eventType
      };
    }
  }
  _exports.default = EnaxActionModel;
});