define("@woody-lite/addon-tasks/models/task", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/models/base", "@ember/object", "@ember/service"], function (_exports, _base, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const HOUR_DAY = 'HH:mm';
  const TASKS_METADATA = {
    TCTF_TASK_002: {
      title: 'label.signModule.taskHeaders.creditLimitModification',
      action: 'goToTaskDetail'
    },
    KUUD_TC_Task1_ID: {
      title: 'label.signModule.taskHeaders.accountTransfer',
      action: 'goToIncidents'
    },
    KPAP_TASK_001: {
      title: 'label.signModule.taskHeaders.requestOperation',
      action: 'goToTaskDetail'
    },
    KPAP_TASK_003: {
      title: 'label.signModule.taskHeaders.requestOperation003',
      action: 'goToTaskDetail'
    },
    'KBYR_Task1_ID-KGCR_CP001': {
      title: 'label.signModule.taskHeaders.requestRecruitmentAccount',
      action: 'goToTaskDetail'
    },
    'KBYR_Task1_ID-KGCR_00812': {
      title: 'label.signModule.taskHeaders.requestRecruitmentCard',
      action: 'goToTaskDetail'
    },
    'KBYR_Task1_ID-KGCR_GV002': {
      title: 'label.signModule.taskHeaders.requestMortgageDocumentation',
      action: 'goToTaskDetail'
    }
  };
  let TaskModel = _exports.default = (_class = class TaskModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _defineProperty(this, "timeFormat", HOUR_DAY);
    }
    get action() {
      var _TASKS_METADATA$get;
      return (_TASKS_METADATA$get = TASKS_METADATA[(0, _object.get)(this, 'formatId')]) === null || _TASKS_METADATA$get === void 0 ? void 0 : _TASKS_METADATA$get.action;
    }
    get displayDataId() {
      return (0, _object.get)(this, 'description');
    }
    get creationDate() {
      return (0, _object.get)(this, 'createdDate');
    }
    get expirationDate() {
      return (0, _object.get)(this, 'expireDate');
    }
    get title() {
      var _TASKS_METADATA$get2;
      return (_TASKS_METADATA$get2 = TASKS_METADATA[(0, _object.get)(this, 'formatId')]) === null || _TASKS_METADATA$get2 === void 0 ? void 0 : _TASKS_METADATA$get2.title;
    }
    accept() {
      return this.provider.push('tasks', '_acceptTask', this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});