define("@woody-lite/core-session/models/customer", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/models/base", "@ember/object", "@ember/utils", "@ember/object/computed", "@ember/service", "@woody-lite/core-session/models/client", "@glimmer/tracking", "@woody-lite/core-session/utils/initials", "@ember/application"], function (_exports, _base, _object, _utils, _computed, _service, _client, _tracking, _initials, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const FREELANCE_ECONOMIC_DATA = '1';
  const GLOBAL_ID = 'GB';
  const MULTICHANNEL_PASSPORT_SIGNED = 'S';
  const FREELANCE_USER_REGEX = '^((8[0-9])|(6[4-8]))$';
  function getAge(birthDate) {
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    if (age < 0) {
      return NaN;
    }
    const monthsDiff = today.getMonth() - birthDate.getMonth();
    if (monthsDiff < 0 || monthsDiff === 0 && today.getDate() < birthDate.getDate()) {
      return age - 1;
    }
    return age;
  }
  let CustomerModel = _exports.default = (_dec = (0, _computed.reads)('customer.name'), _dec2 = (0, _computed.reads)('customer.productMarks.hasPendingPurchase'), _dec3 = (0, _computed.reads)('customer.lastName'), _dec4 = (0, _computed.reads)('customer.mothersLastName'), _dec5 = (0, _computed.reads)('customer.branch.id'), _dec6 = (0, _computed.reads)('customer.classification.segments'), _dec7 = (0, _computed.reads)('session.authenticationType'), _dec8 = (0, _computed.reads)('session.personalization'), _dec9 = (0, _computed.reads)('customer.managementData.IsIdentified'), _dec10 = (0, _computed.reads)('customer.managementData.hasFatcaCrsOperativeBlockage'), _dec11 = (0, _computed.reads)('customer.managementData.hasRbaOperativeBlockage'), _dec12 = (0, _computed.reads)('customer.extendedData.sex'), _dec13 = (0, _computed.reads)('customer.birthDate'), _dec14 = (0, _object.computed)('customer.birthDate'), _dec15 = (0, _computed.filterBy)('segments', 'name', GLOBAL_ID), _dec16 = (0, _object.computed)('customer.economicData', 'globalSegments'), _dec17 = (0, _object.computed)('customer.contactsInformation.@each.checked'), _dec18 = (0, _object.computed)('customer.contactsInformation.@each.status'), _dec19 = (0, _object.computed)('customer.contactsInformation.@each.name'), _dec20 = (0, _object.computed)('customer.contactsInformation.@each.checked'), _dec21 = (0, _object.computed)('customer.contactsInformation.@each.status'), _dec22 = (0, _object.computed)('customer.contactsInformation.@each.name'), _dec23 = (0, _computed.equal)('customer.multichannelPassport.attachedDocumentSigned.id', MULTICHANNEL_PASSPORT_SIGNED), _dec24 = (0, _computed.bool)('customer.employeeData.isEmployee'), _class = class CustomerModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "_isPhoneValidated", _descriptor2, this);
      _initializerDefineProperty(this, "_emailStatus", _descriptor3, this);
      _initializerDefineProperty(this, "_phoneStatus", _descriptor4, this);
      _initializerDefineProperty(this, "_email", _descriptor5, this);
      _initializerDefineProperty(this, "hasBeenRedirectedToWelcome", _descriptor6, this);
      _initializerDefineProperty(this, "name", _descriptor7, this);
      _initializerDefineProperty(this, "hasPendingPurchase", _descriptor8, this);
      _initializerDefineProperty(this, "lastName", _descriptor9, this);
      _initializerDefineProperty(this, "mothersLastName", _descriptor10, this);
      _initializerDefineProperty(this, "branch", _descriptor11, this);
      _initializerDefineProperty(this, "segments", _descriptor12, this);
      _initializerDefineProperty(this, "authenticationType", _descriptor13, this);
      _initializerDefineProperty(this, "personalization", _descriptor14, this);
      _initializerDefineProperty(this, "customerIsIdentified", _descriptor15, this);
      _initializerDefineProperty(this, "isFatcaBlocked", _descriptor16, this);
      _initializerDefineProperty(this, "isRbaBlocked", _descriptor17, this);
      _initializerDefineProperty(this, "sex", _descriptor18, this);
      _initializerDefineProperty(this, "birthDate", _descriptor19, this);
      _initializerDefineProperty(this, "globalSegments", _descriptor20, this);
      _initializerDefineProperty(this, "_hasSignedMultichannelContract", _descriptor21, this);
      _initializerDefineProperty(this, "hasSignedServiceAppendix", _descriptor22, this);
      _initializerDefineProperty(this, "isEmployee", _descriptor23, this);
    }
    get session() {
      return this.store.content.find(session => session instanceof _client.default && session.id === this.id);
    }
    get age() {
      const birthDate = (0, _object.get)(this, 'customer.birthDate');
      if (!birthDate) {
        return NaN;
      }
      return getAge(new Date(birthDate));
    }
    get fullName() {
      const {
        name,
        lastName,
        mothersLastName
      } = this;
      return [name, lastName, mothersLastName].filter(Boolean).join(' ');
    }
    get initials() {
      const surname = this.lastName || this.mothersLastName;
      return (0, _initials.default)((0, _object.get)(this, 'name'), surname);
    }
    get isFreelance() {
      const economicData = (0, _object.get)(this, 'customer.economicData');
      if (economicData === FREELANCE_ECONOMIC_DATA) {
        return true;
      }
      const globalSegments = this.globalSegments;
      const regForTest = new RegExp(FREELANCE_USER_REGEX);
      return (0, _utils.isPresent)(globalSegments) && globalSegments.some(currentSegment => regForTest.test(currentSegment.value));
    }
    getContactInformation(type) {
      const contactsInformation = (0, _object.get)(this, 'customer.contactsInformation');
      return contactsInformation === null || contactsInformation === void 0 ? void 0 : contactsInformation.find(contact => contact.type.name === type);
    }
    get isPhoneValidatedComputed() {
      var _this$getContactInfor;
      return Boolean((_this$getContactInfor = this.getContactInformation('CELLPHONE')) === null || _this$getContactInfor === void 0 ? void 0 : _this$getContactInfor.checked);
    }
    get isPhoneValidated() {
      return (0, _utils.isNone)(this._isPhoneValidated) ? this.isPhoneValidatedComputed : this._isPhoneValidated;
    }
    set isPhoneValidated(value) {
      this._isPhoneValidated = value;
    }
    get phoneStatusComputed() {
      var _this$getContactInfor2;
      return (_this$getContactInfor2 = this.getContactInformation('CELLPHONE')) === null || _this$getContactInfor2 === void 0 ? void 0 : _this$getContactInfor2.status;
    }
    get phoneStatus() {
      return (0, _utils.isNone)(this._phoneStatus) ? this.phoneStatusComputed : this._phoneStatus;
    }
    set phoneStatus(value) {
      this._phoneStatus = value;
    }
    get phoneComputed() {
      var _this$getContactInfor3;
      return (_this$getContactInfor3 = this.getContactInformation('CELLPHONE')) === null || _this$getContactInfor3 === void 0 ? void 0 : _this$getContactInfor3.name;
    }
    get phone() {
      return (0, _utils.isNone)(this._phone) ? this.phoneComputed : this._phone;
    }
    set phone(value) {
      this._phone = value;
    }
    get isEmailValidated() {
      var _this$getContactInfor4;
      return Boolean((_this$getContactInfor4 = this.getContactInformation('MAIL')) === null || _this$getContactInfor4 === void 0 ? void 0 : _this$getContactInfor4.checked);
    }
    get emailStatusComputed() {
      var _this$getContactInfor5;
      return (_this$getContactInfor5 = this.getContactInformation('MAIL')) === null || _this$getContactInfor5 === void 0 ? void 0 : _this$getContactInfor5.status;
    }
    get emailStatus() {
      return (0, _utils.isNone)(this._emailStatus) ? this.emailStatusComputed : this._emailStatus;
    }
    set emailStatus(value) {
      this._emailStatus = value;
    }
    get emailComputed() {
      var _this$getContactInfor6;
      return (_this$getContactInfor6 = this.getContactInformation('MAIL')) === null || _this$getContactInfor6 === void 0 ? void 0 : _this$getContactInfor6.name;
    }
    get email() {
      return (0, _utils.isNone)(this._email) ? this.emailComputed : this._email;
    }
    set email(value) {
      this._email = value;
    }
    get hasPreGrantedLoan() {
      var _config$session, _config$session$clien;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const hasPreGrantedLoan = ((_config$session = config.session) === null || _config$session === void 0 ? void 0 : (_config$session$clien = _config$session.client) === null || _config$session$clien === void 0 ? void 0 : _config$session$clien.hasPregrantedLoan) || (0, _object.get)(this, 'customer.productMarks.hasPreGrantedLoan.id') !== 'N';
      return hasPreGrantedLoan;
    }
    get hasSignedMultichannelContract() {
      return (0, _utils.isNone)(this._hasSignedMultichannelContract) ? (0, _object.get)(this, 'customer.multichannelPassport.signed.id') === MULTICHANNEL_PASSPORT_SIGNED : this._hasSignedMultichannelContract;
    }
    set hasSignedMultichannelContract(value) {
      this._hasSignedMultichannelContract = value;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_isPhoneValidated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_emailStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_phoneStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_email", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasBeenRedirectedToWelcome", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasPendingPurchase", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "mothersLastName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "branch", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "segments", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "authenticationType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "personalization", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "customerIsIdentified", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isFatcaBlocked", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "isRbaBlocked", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "sex", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "birthDate", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "age", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "age"), _class.prototype), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "globalSegments", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isFreelance", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "isFreelance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPhoneValidatedComputed", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "isPhoneValidatedComputed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "phoneStatusComputed", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "phoneStatusComputed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "phoneComputed", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "phoneComputed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEmailValidated", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "isEmailValidated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emailStatusComputed", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "emailStatusComputed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emailComputed", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "emailComputed"), _class.prototype), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "_hasSignedMultichannelContract", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "hasSignedServiceAppendix", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "isEmployee", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});