define("ember-promise-helpers/helpers/promise-rejected-reason", ["exports", "ember-promise-helpers/helpers/await"], function (_exports, _await) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _await.default.extend({
    compute(params) {
      const maybePromise = params[0];
      return this.ensureLatestPromise(maybePromise, promise => {
        promise.then(() => {
          this.setValue(null, maybePromise);
        }).catch(reason => {
          this.setValue(reason, maybePromise);
        });
      });
    }
  });
});