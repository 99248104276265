define("@woody-lite/addon-utils/utils/capitalize-first", ["exports", "ember-utils/utils/strings/ucwordslower", "ember-utils/utils/strings/ucfirst", "@ember/array"], function (_exports, _ucwordslower, _ucfirst, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalizeFirst = capitalizeFirst;
  function capitalizeFirst(params) {
    const value = (0, _array.isArray)(params) ? params[0] : params;
    return (0, _ucfirst.default)((0, _ucwordslower.default)(value)).trim();
  }
});