define("@woody-lite/addon-feedback/decorators/on-leave-feedback", ["exports", "@ember/service", "@ember/object", "@ember/application", "@ember/utils"], function (_exports, _service, _object, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = onLeaveFeedback;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function onLeaveFeedback(Target) {
    var _class, _descriptor;
    const Klass = (_class = class WillTransition extends Target {
      constructor() {
        super(...arguments);
        _initializerDefineProperty(this, "feedback", _descriptor, this);
      }
      async willTransition(transition) {
        if (super.willTransition) {
          await super.willTransition(...arguments);
        }
        const controller = this.controllerFor(this.routeName);
        this.feedback.isRunning = true;
        try {
          await this.triggerLeavingRouteFeedback({
            targetRouteName: transition.targetName,
            feedbackUrl: this.feedbackUrl,
            feedbackType: this.feedbackType,
            feedbackLang: this.feedbackLang,
            stars: (0, _object.get)(controller, 'stars'),
            hideFeedback: (0, _object.get)(controller, 'hideFeedback')
          });
        } catch {
          //
        }
        this.feedback.isRunning = false;
      }
      async triggerLeavingRouteFeedback(_ref) {
        let {
          targetRouteName,
          feedbackUrl,
          feedbackType,
          feedbackLang,
          stars,
          hideFeedback
        } = _ref;
        const currentRouteName = this.fullRouteName;
        const destinationHasFeedback = this.checkFeedbackBehaviour(targetRouteName);
        if (destinationHasFeedback && !hideFeedback) {
          await this.feedback.openModal(currentRouteName, feedbackUrl, feedbackType, feedbackLang, stars);
        }
      }
      checkFeedbackBehaviour(targetRoute) {
        const appConfig = (0, _application.getOwner)(this).resolveRegistration('config:environment');
        const destinationRoutes = appConfig.APP.DESTINATION_ROUTES;
        const routeName = destinationRoutes.filter(route => route === targetRoute);
        return !(0, _utils.isEmpty)(routeName);
      }
    }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "feedback", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _class);
    Object.defineProperty(Klass, 'name', {
      value: Target.name,
      writable: true,
      configurable: true
    });
    return Klass;
  }
});