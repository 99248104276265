define("liquid-fire/templates/components/liquid-sync", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "TSVMEUq3",
    "block": "[[[18,1,[[28,[37,1],[[30,0],\"ready\"],null]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"action\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-sync.hbs",
    "isStrictMode": false
  });
});