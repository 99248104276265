define("@woody-lite/addon-crm-campaigns/normalizers/crm-campaign-content-fragment", ["exports", "@ember/object", "@ember/service", "@woody-mrs-potato/addon-utils/utils/iso-converter"], function (_exports, _object, _service, _isoConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const LDPI = 'ldpi';
  let CrmCampaignContentFragmentNormalizer = _exports.default = (_class = class CrmCampaignContentFragmentNormalizer extends _object.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    _digestCampaign(campaignId, elements) {
      var _elements$familyCode, _elements$subFamilyCo, _elements$productCode, _elements$opinatorId, _elements$title, _elements$pretitle, _elements$description, _elements$claim, _elements$opinatorTit, _elements$opinatorBut, _elements$imageBackgo, _elements$imageName, _elements$imageUrl, _elements$iconName, _elements$iconUrl, _elements$urlTarget, _elements$target, _elements$location, _elements$priority, _elements$position, _elements$countdown;
      return {
        id_advert: campaignId,
        familyCode: (_elements$familyCode = elements.familyCode) === null || _elements$familyCode === void 0 ? void 0 : _elements$familyCode.value,
        subFamilyCode: (_elements$subFamilyCo = elements.subFamilyCode) === null || _elements$subFamilyCo === void 0 ? void 0 : _elements$subFamilyCo.value,
        productCode: (_elements$productCode = elements.productCode) === null || _elements$productCode === void 0 ? void 0 : _elements$productCode.value,
        opinatorId: (_elements$opinatorId = elements.opinatorId) === null || _elements$opinatorId === void 0 ? void 0 : _elements$opinatorId.value,
        details_advert: [{
          lang: (0, _isoConverter.default)(this.intl.primaryLocale),
          title: (_elements$title = elements.title) === null || _elements$title === void 0 ? void 0 : _elements$title.value,
          pretitle: (_elements$pretitle = elements.pretitle) === null || _elements$pretitle === void 0 ? void 0 : _elements$pretitle.value,
          description: (_elements$description = elements.description) === null || _elements$description === void 0 ? void 0 : _elements$description.value,
          claim: (_elements$claim = elements.claim) === null || _elements$claim === void 0 ? void 0 : _elements$claim.value,
          opinatorTitle: (_elements$opinatorTit = elements.opinatorTitle) === null || _elements$opinatorTit === void 0 ? void 0 : _elements$opinatorTit.value,
          opinatorButtonText: (_elements$opinatorBut = elements.opinatorButtonText) === null || _elements$opinatorBut === void 0 ? void 0 : _elements$opinatorBut.value,
          imageBackground: (_elements$imageBackgo = elements.imageBackgound) === null || _elements$imageBackgo === void 0 ? void 0 : _elements$imageBackgo.value,
          imageName: (_elements$imageName = elements.imageName) === null || _elements$imageName === void 0 ? void 0 : _elements$imageName.value,
          imageUrl: [{
            resolution: LDPI,
            url_image: (_elements$imageUrl = elements.imageUrl) === null || _elements$imageUrl === void 0 ? void 0 : _elements$imageUrl.value
          }],
          iconName: (_elements$iconName = elements.iconName) === null || _elements$iconName === void 0 ? void 0 : _elements$iconName.value,
          iconUrl: (_elements$iconUrl = elements.iconUrl) === null || _elements$iconUrl === void 0 ? void 0 : _elements$iconUrl.value,
          url_target: (_elements$urlTarget = elements.urlTarget) === null || _elements$urlTarget === void 0 ? void 0 : _elements$urlTarget.value,
          target: (_elements$target = elements.target) === null || _elements$target === void 0 ? void 0 : _elements$target.value
        }],
        priorities_advert: [{
          location: (_elements$location = elements.location) === null || _elements$location === void 0 ? void 0 : _elements$location.value,
          priority: (_elements$priority = elements.priority) === null || _elements$priority === void 0 ? void 0 : _elements$priority.value,
          position: (_elements$position = elements.position) === null || _elements$position === void 0 ? void 0 : _elements$position.value,
          countdown: (_elements$countdown = elements.countdown) !== null && _elements$countdown !== void 0 && _elements$countdown.value ? 'true' : 'false'
        }]
      };
    }
    normalize(data) {
      var _data$Items, _data$Items$root;
      /* istanbul ignore next */
      const campaigns = ((_data$Items = data[':items']) === null || _data$Items === void 0 ? void 0 : (_data$Items$root = _data$Items.root) === null || _data$Items$root === void 0 ? void 0 : _data$Items$root[':items']) || {};
      const newCampaigns = [];

      // Iteration on the content fragments
      // Only one content fragment should come
      Object.keys(campaigns).forEach(key => {
        const campaign = campaigns[key];
        const campaignId = campaign.title;
        /* istanbul ignore next */
        const elements = campaign.elements || {};

        // We only keep the one that has id
        if (campaignId) {
          newCampaigns.push(this._digestCampaign(campaignId, elements));
        }
      });

      // We return the first element with id
      /* istanbul ignore next */
      return newCampaigns.find(campaign => campaign.id_advert) || {};
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});