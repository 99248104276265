define("@woody-lite/addon-feedback/normalizers/aggregated-feedback", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AggregatedFeedbackNormalizer extends _object.default {
    normalize(data) {
      return Object.assign({}, data.behavioralEventForm, data.aggregatedFeedback);
    }
  }
  _exports.default = AggregatedFeedbackNormalizer;
});