define("@woody-lite/core-session/components/modal-safe-tap-otp", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/service", "@glimmer/tracking", "@ember/object", "@woody-lite/core-session/errors/user-choose-signature-method", "@woody-lite/core-session/errors/safetap-technical", "@woody-lite/core-session/constants", "@woody-lite/core-session/templates/modal-safe-tap-otp"], function (_exports, _modal, _service, _tracking, _object, _userChooseSignatureMethod, _safetapTechnical, _constants, _modalSafeTapOtp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const USER_CREDENTIALS_SMC = 'SMC201600442';
  let ModalSafeTapOtpComponent = _exports.default = (_class = class ModalSafeTapOtpComponent extends _modal.default {
    constructor() {
      var _this$veil;
      super(...arguments);
      _defineProperty(this, "layout", _modalSafeTapOtp.default);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _initializerDefineProperty(this, "slinky", _descriptor3, this);
      _initializerDefineProperty(this, "veil", _descriptor4, this);
      _defineProperty(this, "resource", null);
      _defineProperty(this, "challenge", '');
      _defineProperty(this, "publicServerKey", '');
      _initializerDefineProperty(this, "experience", _descriptor5, this);
      _initializerDefineProperty(this, "isRecoveringAccess", _descriptor6, this);
      _initializerDefineProperty(this, "moreOneCards", _descriptor7, this);
      _initializerDefineProperty(this, "card", _descriptor8, this);
      if ((_this$veil = this.veil) !== null && _this$veil !== void 0 && _this$veil.show) {
        this.veil.remove();
      }
    }
    get canSelecthAnotherType() {
      return this.model.options.canSelecthAnotherType;
    }
    async isNfcEnabled() {
      const capabilities = await this.provider.fetch('device', 'getCapabilities');
      return (0, _object.get)(capabilities, 'isNfcEnabled');
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.resource = this.model.options.resource;
      const authenticationresponse = JSON.parse(atob(this.resource.authenticationresponse));
      this.challenge = authenticationresponse.challenge[0];
      this.publicServerKey = authenticationresponse.publicKey[0];
      this.isRecoveringAccess = this.resource.smc === USER_CREDENTIALS_SMC;
    }
    async didInsertElement() {
      super.didInsertElement(...arguments);
      const cards = await this.getCards();
      this.moreOneCards = Boolean((cards === null || cards === void 0 ? void 0 : cards.length) > 1);
      if (cards !== null && cards !== void 0 && cards.length) {
        var _cards$content;
        this.card = (_cards$content = cards.content) === null || _cards$content === void 0 ? void 0 : _cards$content[0];
      }
      await this.initNFC();
    }
    async willDestroy() {
      super.willDestroy(...arguments);
      await this.stopNFC();
    }
    async initNFC() {
      if (!(await this.isNfcEnabled())) {
        try {
          await this.modal.open('safe-tap-settings', {
            canSelecthAnotherType: this.canSelecthAnotherType
          });
        } catch (e) {
          if (e instanceof _userChooseSignatureMethod.UserChooseSignatureMethodError) {
            return this.anotherType();
          }
          if (!(await this.isNfcEnabled())) {
            return this.closeModal();
          }
        }
      }
      return this.startNFC();
    }
    getSafeTapSdkError(e) {
      const [chunk] = e.errorInfo.response.split('-');
      return chunk.split(' ')[1].trim();
    }
    async showError(errorType) {
      try {
        await this.modal.open('safe-tap-error', {
          errorType
        });
        this.reject();
      } catch (e) {
        if (e instanceof _userChooseSignatureMethod.UserChooseSignatureMethodError) {
          this.anotherType();
        }
        if (e instanceof _safetapTechnical.SafeTapTechnicalError) {
          this.closeModal();
        }
      }
    }
    async handleError(e) {
      if (e.code === 'SDK') {
        const sdkError = this.getSafeTapSdkError(e);
        if (sdkError === 'E001') {
          await this.showError(_constants.SAFETAP_ERROR_TYPES.READING_ERROR);
        } else {
          await this.showError(_constants.SAFETAP_ERROR_TYPES.INVALID_CARD_ERROR);
        }
      } else {
        await this.showError(_constants.SAFETAP_ERROR_TYPES.INVALID_CARD_ERROR);
      }
    }
    async getCards() {
      return (await this.provider.pull('valid-cards', 'getValidCards')) || [];
    }
    async startNFC() {
      const {
        challenge,
        publicServerKey
      } = this;
      let signed = false;
      do {
        try {
          const result = await this.slinky.send('SafeTap.init', {
            challenge,
            publicServerKey
          });
          const signedData = result.transactionsData.replace(/\n/g, '');
          const publicKey = result.publicKey.replace(/\n/g, '');
          const authenticationdata = "signedData=".concat(signedData, ",publicKey=").concat(publicKey);
          this.resource.headers.authenticationdata = authenticationdata;
          this.resource.error = false;
          const data = await this.resource.retry();
          signed = true;
          this.resolve(data);
        } catch (e) {
          await this.handleError(e);
        }
      } while (!signed);
    }
    async stopNFC() {
      try {
        await this.slinky.send('SafeTap.cancel');
      } catch {
        //
      }
    }
    async openAvailableCards() {
      await this.stopNFC();
      try {
        await this.modal.open('card-list');
      } catch {
        //
      } finally {
        await this.startNFC();
      }
    }
    closeModal() {
      this.reject();
    }
    anotherType() {
      this.reject({
        chooseAnotherType: true
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "experience", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'A';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isRecoveringAccess", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "moreOneCards", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "card", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "stopNFC", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "stopNFC"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openAvailableCards", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openAvailableCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "anotherType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "anotherType"), _class.prototype), _class);
});