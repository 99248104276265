define("@woody-mrs-potato/addon-task-scheduler/services/scheduler", ["exports", "@ember/application", "@ember/debug", "@ember/runloop", "@ember/service", "@ember/test-waiters"], function (_exports, _application, _debug, _runloop, _service, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.scheduleFrame = scheduleFrame;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const FPS = 60;
  const MILLISECONDS = 1000;
  const {
    requestAnimationFrame,
    cancelAnimationFrame,
    performance
  } = window;
  const waiter = (0, _testWaiters.buildWaiter)('ember-task-scheduler');
  function scheduleFrame(context, method) {
    method = context[method];
    return requestAnimationFrame(method.bind(context));
  }
  class SchedulerService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_tasks", []);
      _defineProperty(this, "_currentInstance", null);
      _defineProperty(this, "_waiterToken", null);
    }
    get config() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }
    get FPS() {
      var _this$config$taskSche;
      return this._FPS || ((_this$config$taskSche = this.config.taskScheduler) === null || _this$config$taskSche === void 0 ? void 0 : _this$config$taskSche.FPS);
    }
    set FPS(value) {
      this._FPS = value;
    }
    get millisecondsPerFrame() {
      const fps = this.FPS || FPS;
      return 1 / fps * MILLISECONDS;
    }
    hasPendingTasks() {
      return this._tasks.length !== 0;
    }
    schedule() {
      const tasks = this._tasks;
      const currentInstance = this._currentInstance;
      tasks.push(this._sliceArguments(...arguments));
      if (!currentInstance) {
        this._begin();
      }
    }
    scheduleOnce() {
      const currentInstance = this._currentInstance;
      this._pushUnique(this._sliceArguments(...arguments));
      if (!currentInstance) {
        this._begin();
      }
    }
    cancel() {
      const currentInstance = this._currentInstance;
      const [target, method] = this._sliceArguments(...arguments);
      const tasks = this._tasks;
      const removedTasks = [];
      const removedIndexes = [];

      // Find removable tasks.
      for (let i = 0; i < tasks.length; i++) {
        const [currentTarget, currentMethod] = tasks[i];
        if (currentTarget === target && currentMethod === method) {
          removedIndexes.push(i);
        }
      }

      // Remove tasks.
      for (let i = 0; i < removedIndexes.length; i++) {
        const index = removedIndexes[i];
        removedTasks.push(tasks.splice(index, 1));
      }
      if (currentInstance && tasks.length === 0) {
        this._end();
      }
      return removedTasks;
    }
    _pushUnique(params) {
      const [target, method, args, stack] = params;
      const tasks = this._tasks;
      for (let i = 0; i < tasks.length; i++) {
        const [currentTarget, currentMethod] = tasks[i];
        if (currentTarget === target && currentMethod === method) {
          tasks[i][2] = args; // replace args
          tasks[i][3] = stack; // eslint-disable-line no-magic-numbers

          return;
        }
      }
      tasks.push(params);
    }
    _begin() {
      (false && !(!this._currentInstance) && (0, _debug.assert)('Could not schedule a new frame. Scheduler instance is already started', !this._currentInstance));
      this._waiterToken = waiter.beginAsync();
      this._currentInstance = scheduleFrame(this, '_loop');
    }
    _next() {
      (false && !(this._currentInstance) && (0, _debug.assert)('Could not schedule next frame. Scheduler instance is not running', this._currentInstance));
      (false && !(this.hasPendingTasks()) && (0, _debug.assert)('Could not schedule next frame. Scheduler has no tasks', this.hasPendingTasks()));
      this._currentInstance = scheduleFrame(this, '_loop');
    }
    _end() {
      const currentInstance = this._currentInstance;
      (false && !(currentInstance) && (0, _debug.assert)('Could not stop scheduler. Service instance is not running', currentInstance));
      cancelAnimationFrame(currentInstance);
      waiter.endAsync(this._waiterToken);
      this._currentInstance = null;
    }

    // istanbul ignore next
    willDestroy() {
      super.willDestroy(...arguments);
      if (this._currentInstance) {
        this._end();
      }
    }
    _loop(startTime) {
      // istanbul ignore if: lifecycle
      if (this.isDestroyed) {
        return;
      }
      const millisecondsPerFrame = this.millisecondsPerFrame;
      const tasks = this._tasks;
      let target, method, args, stack;
      (false && !(tasks.length !== 0) && (0, _debug.assert)('Could not run current loop. Service instance has no tasks.', tasks.length !== 0));
      do {
        [target, method, args, stack] = tasks.shift();
        this._exec(target, method, args, stack);
      } while (!this.isDestroyed && tasks.length > 0 && performance.now() - startTime < millisecondsPerFrame);

      // After exec, service could be destroyed. Recheck.
      // istanbul ignore if: lifecycle
      if (this.isDestroyed) {
        return;
      }
      if (tasks.length > 0) {
        this._next();
        return;
      }
      const currentInstance = this._currentInstance;
      if (currentInstance) {
        this._end();
      }
    }
    _exec(target, method, args, stack) {
      (0, _runloop.run)(() => {
        try {
          return method.apply(target, args);
        } catch (e) {
          return new Error(stack || e);
        }
      });
    }
    _sliceArguments(target, method) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }
      const env = this.config.environment;
      const length = arguments.length;
      if (length === 1) {
        method = target;
        target = null;
      }
      if (typeof method === 'string') {
        method = target[method];
      }
      const stack = env === 'development' ? /* istanbul ignore next */new Error() : null;
      (false && !(method && typeof method === 'function') && (0, _debug.assert)('Could not find a valid method to call', method && typeof method === 'function'));
      return [target, method, args, stack];
    }
  }
  _exports.default = SchedulerService;
});