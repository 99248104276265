define("@woody-mrs-potato/addon-utils/utils/iso-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isoConverter;
  const invert = obj => Object.keys(obj).reduce((acc, key) => {
    acc[obj[key]] = key;
    return acc;
  }, {});
  const rules = {
    'ISO 639‑1/ISO 639‑2': {
      es: 'spa',
      en: 'eng',
      ca: 'cat',
      eu: 'eus',
      gl: 'glg',
      it: 'ita'
    }
  };
  rules['ISO 639‑2/ISO 639‑1'] = invert(rules['ISO 639‑1/ISO 639‑2']);
  function isoConverter(lang) {
    let {
      from = 'ISO 639‑1',
      to = 'ISO 639‑2'
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return rules["".concat(from, "/").concat(to)][lang];
  }
});