define("@woody-lite/core-session/decorators/authenticated", ["exports", "@ember/debug", "@ember/routing/route", "@woody-lite/core-session/decorators/session", "@ember/service", "@ember/object"], function (_exports, _debug, _route, _session, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = authenticated;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function authenticated(_ref) {
    let {
      app
    } = _ref;
    (false && !(typeof app === 'string') && (0, _debug.assert)("The 'authenticated' decorator needs an object with 'app' property", typeof app === 'string'));
    return function (target) {
      var _dec, _class, _descriptor, _descriptor2;
      (false && !(_route.default.detect(target)) && (0, _debug.assert)("The 'authenticated' decorator must be applied to a Route", _route.default.detect(target)));
      const klass = (_dec = (0, _session.default)(app), _class = class _class extends target {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "currentSession", _descriptor, this);
          _initializerDefineProperty(this, "router", _descriptor2, this);
        }
        __checkSession(transition, currentSession) {
          if (!(0, _object.get)(currentSession || {}, 'tsec')) {
            var _this$router$_router$, _this$router$_router$2, _this$router$_router$3, _this$router$_router$4, _this$router$location, _this$router$location2, _this$router$location3;
            const transitionPath = (_this$router$_router$ = this.router._router.currentState) === null || _this$router$_router$ === void 0 ? void 0 : (_this$router$_router$2 = _this$router$_router$.router) === null || _this$router$_router$2 === void 0 ? void 0 : (_this$router$_router$3 = _this$router$_router$2.activeTransition) === null || _this$router$_router$3 === void 0 ? void 0 : (_this$router$_router$4 = _this$router$_router$3.intent) === null || _this$router$_router$4 === void 0 ? void 0 : _this$router$_router$4.url;
            const url = transitionPath || ((_this$router$location = this.router.location) === null || _this$router$location === void 0 ? void 0 : (_this$router$location2 = _this$router$location.location) === null || _this$router$location2 === void 0 ? void 0 : (_this$router$location3 = _this$router$location2.hash) === null || _this$router$location3 === void 0 ? void 0 : _this$router$location3.replace(/^#/, ''));
            transition.send(false, 'unauthenticated', url);
          }
        }
        async beforeModel(transition) {
          const {
            currentSession
          } = this;
          this.__checkSession(transition, currentSession);
          return super.beforeModel(...arguments);
        }
      }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _class);
      Object.defineProperty(klass, 'name', {
        value: target.name
      });
      return klass;
    };
  }
});