define("@woody-lite/engine-loans/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function serializeLoan(model) {
    return {
      loan_id: model.id
    };
  }
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('loan', {
      path: '/:product_id/more-info'
    });
    this.route('mortgage', {
      path: 'mortgage/:product_id/more-info'
    });
    this.route('overview', function () {
      this.route('loan', {
        path: 'loan/:loan_id',
        serialize: serializeLoan
      });
      this.route('mortgage', {
        path: 'mortgage/:loan_id',
        serialize: serializeLoan
      });
    });
  });
});