define("@woody-lite/addon-conversations/repositories/messages", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/repositories/repository", "@ember/service", "@ember/object", "@ember/application"], function (_exports, _repository, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DEFAULT_MANAGER = {
    id: 'CC'
  };
  const MANAGER = {
    id: 'G'
  };
  let MessagesRepository = _exports.default = (_class = class MessagesRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
    }
    _getManager(manager) {
      if (manager.userCode) {
        return {
          name: manager.name,
          lastName: manager.lastName,
          mothersLastName: manager.mothersLastName,
          type: MANAGER
        };
      }
      return {
        name: 'PHILIP1',
        lastName: 'PHILIP1',
        type: DEFAULT_MANAGER
      };
    }
    async createMessage(threadId, message, attachments) {
      const customer = await this.provider.pull('customer', 'getContextualData');
      const payload = {
        content: message,
        sender: {
          name: customer.name,
          lastName: customer.lastName,
          mothersLastName: customer.mothersLastName,
          type: {
            id: 'C',
            name: 'CLIENTE'
          }
        },
        receiver: this._getManager((0, _object.get)(customer, 'manager')),
        isDraft: false,
        attachments
      };
      const data = await this.enax.POST('messages', payload, {
        threadId
      });
      const normalizer = (0, _application.getOwner)(this).lookup('normalizer:thread-message');
      const normalizeMessage = normalizer.normalize(data);
      return this.createEntry('thread-message', normalizeMessage.properties.id, normalizeMessage.properties);
    }
    markAsRead(message) {
      return this.enax.PUT('messages', {
        readed: true
      }, {
        threadId: (0, _object.get)(message, 'messageThreadId'),
        messageId: message.id
      });
    }
    async getDetail(model) {
      const payload = {
        threadId: (0, _object.get)(model, 'messageThreadId'),
        messageId: (0, _object.get)(model, 'id')
      };
      const data = await this.enax.GET('messages', payload);
      const normalizer = (0, _application.getOwner)(this).lookup('normalizer:thread-message-detail');
      const normalizeDetail = normalizer.normalize(data);
      const modelizeAttachments = normalizeDetail.properties.attachments.map(attachment => this.createEntry('thread-message-attachment', attachment.id, attachment));
      normalizeDetail.properties.attachments = modelizeAttachments;
      return this.createEntry('thread-message-detail', normalizeDetail.properties.id, normalizeDetail.properties);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});