define("@woody-mrs-potato/utils-ember-transition-end/utils/on-transition-end", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = onTransitionEnd;
  const eventNames = {
    transition: 'transitionend',
    MozTransition: 'transitionend',
    OTransition: 'oTransitionEnd',
    WebkitTransition: 'webkitTransitionEnd',
    msTransition: 'MSTransitionEnd'
  };

  /**
   * Find transition-end event name on current browser.
   *
   * @method findTransitionEventName
   * @return Boolean
   * @private
   */
  function findTransitionEventName() {
    const div = document.createElement('div');
    const key = Object.keys(eventNames).find(eventName => eventName in div.style);
    return eventNames[key];
  }
  const transitionEndEventName = findTransitionEventName();

  /**
   * Subscribes a callback to a transition-end event by transition property on a given element.
   *
   * @method onTransitionEnd
   * @param {Element} element
   * @param {Function} callback
   * @param {Object} options
   */
  function onTransitionEnd(element, callback) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const {
      transitionProperty = 'all',
      once = false,
      onlyTarget = false
    } = options;
    function transitionCallback(e) {
      const {
        propertyName,
        target
      } = e;
      if (onlyTarget && target !== element) {
        return;
      }
      if (transitionProperty !== 'all' && propertyName !== transitionProperty) {
        return;
      }
      if (once) {
        removeEventListener();
      }
      (0, _runloop.run)(null, callback, e);
    }
    function removeEventListener() {
      element.removeEventListener(transitionEndEventName, transitionCallback, true);
    }
    element.addEventListener(transitionEndEventName, transitionCallback, true);
    return removeEventListener;
  }
});