define("ember-cli-markdown-resolver/services/markdown-resolver", ["exports", "@ember/service", "@ember/application", "@ember/array", "@ember/object", "ember-cli-markdown-resolver/files", "rsvp"], function (_exports, _service, _application, _array, _object, _files, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* istanbul ignore file */

  class MarkdownResolverService extends _service.default {
    constructor() {
      super(...arguments);
      this.files = (0, _array.A)(_files.files);
      this.config = (0, _application.getOwner)(this).resolveRegistration('config:environment')['ember-cli-markdown-resolver'] || {};
      this.trees = this.processTress();
    }
    processTress() {
      return Object.keys(_files.trees).reduce((allTrees, key) => {
        allTrees[key] = {
          name: this.getTreeName(key),
          path: key,
          files: this.orderFiles(_files.trees[key])
        };
        return allTrees;
      }, {});
    }
    _file(tree, file) {
      tree = this.getPathFromTreeName(tree);
      return this.files.findBy('path', "".concat(tree, "/").concat(file));
    }
    file(tree, file) {
      return (0, _rsvp.resolve)(this._file(tree, file));
    }
    _tree(tree) {
      tree = this.getPathFromTreeName(tree);
      return (0, _object.get)(this, "trees.".concat(tree)) === undefined ? [] : (0, _object.get)(this, "trees.".concat(tree));
    }
    tree(tree) {
      return (0, _rsvp.resolve)(this._tree(tree));
    }
    getTreeName(path) {
      let folders = this.config.folders;
      return Object.keys(folders).find(key => {
        return folders[key] === path;
      });
    }
    getPathFromTreeName(treeName) {
      return (0, _object.get)(this, "config.folders.".concat(treeName));
    }
    orderFiles(files) {
      files = (0, _array.A)(files).sortBy('attributes.order');
      files.forEach(file => {
        var _file$attributes;
        let inTree = file === null || file === void 0 ? void 0 : (_file$attributes = file.attributes) === null || _file$attributes === void 0 ? void 0 : _file$attributes.inTree;
        if (inTree === false) {
          return files.removeObject(file);
        }
        let children = file.children;
        if (children) {
          file.children = this.orderFiles(children);
        }
      });
      return files;
    }
  }
  _exports.default = MarkdownResolverService;
});