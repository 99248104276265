define("@woody-lite/addon-crm-campaigns/caass/experience-detail", ["exports", "@woody-lite/core-network/resources/caas", "@ember/application"], function (_exports, _caas, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ExperienceDetailCaas extends _caas.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endpoint", '/content/caas/:country/app/experience/:country-app-experience.model.:id.json');
    }
    get country() {
      var _config$crmCampaigns;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return (config === null || config === void 0 ? void 0 : (_config$crmCampaigns = config['crm-campaigns']) === null || _config$crmCampaigns === void 0 ? void 0 : _config$crmCampaigns.country) || 'spain';
    }
  }
  _exports.default = ExperienceDetailCaas;
});