define("@woody-lite/addon-customer-marks/normalizers/mark", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MarkNormalizer extends _object.default {
    normalize(mark) {
      return {
        ...mark
      };
    }
  }
  _exports.default = MarkNormalizer;
});