define("@woody-mrs-potato/addon-vertical-collection/components/virtual-scroll", ["exports", "@html-next/vertical-collection/components/vertical-collection/component", "@ember/service", "@glimmer/tracking"], function (_exports, _component, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // https://developers.google.com/web/updates/2016/04/scroll-anchoring
  const SUPPORT_SCROLL_ANCHORING = CSS && CSS.supports && CSS.supports('(overflow-anchor: auto)');
  let VirtualScroll = _exports.default = (_class = class VirtualScroll extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "memoryScroll", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "state", _descriptor3, this);
      _defineProperty(this, "restoreScroll", true);
      _defineProperty(this, "offsetKey", this.router.currentRouteName);
      _defineProperty(this, "estimateHeightKey", "virtual-estimate-height-".concat(this.router.currentRouteName));
      _defineProperty(this, "SUPPORT_SCROLL_ANCHORING", SUPPORT_SCROLL_ANCHORING);
      _initializerDefineProperty(this, "_bufferSize", _descriptor4, this);
      _initializerDefineProperty(this, "_estimateheight", _descriptor5, this);
    }
    /*
     * Do not recompute this router properties, the key should be the same while transitioning
     */
    /*
     * Returns the cached estimated height if exists and if the browser has
     * a next state (which means that we're going backwards in the history)
     */
    _getCacheEstimateHeight() {
      return this.state.next && this.memoryScroll[this.estimateHeightKey];
    }
    get estimateHeight() {
      return this._getCacheEstimateHeight() || this._estimateHeight;
    }
    set estimateHeight(value) {
      this._estimateHeight = value;
    }
    get bufferSize() {
      return this._bufferSize;
    }
    set bufferSize(value) {
      this._bufferSize = value;
    }
    init() {
      super.init(...arguments);
      const checkFirstRender = this.checkFirstRender.bind(this);
      const currentRoute = this.router.currentRouteName;

      // Cannot reopen/override/break this instance so the dark magic begins now,
      // a proxy should to intercept calls to "_sendActions()" to send an action in the first render.
      //
      // Check the "Radar" class for more info:
      // https://github.com/html-next/vertical-collection/blob/master/addon/-private/data-view/radar/radar.js#L258
      this._radar = new Proxy(this._radar, {
        // Intercept the function "getter", it's called before the invocation
        get(target, key) {
          if (typeof target[key] === 'function' && key === '_sendActions') {
            checkFirstRender(target);
          }
          return Reflect.get(target, key);
        }
      });

      // Override this properties, the key should be the same during a transition
      // and always before the template gets rendered that's why it's defined also
      // in the component context
      this.offsetKey = currentRoute;
      this.estimateHeightKey = "virtual-estimate-height-".concat(currentRoute);
    }
    checkFirstRender(radar) {
      // Seen on the "Radar" code, it's the only way to check if the component has rendered something.
      if (radar._didReset || radar.firstVisibleIndex !== radar._prevFirstVisibleIndex) {
        // Internal flag to know if the component has been rendered
        if (!this._firstRender) {
          // Dispath an action, just for having another hook
          if (this.onFirstRender) {
            this.onFirstRender();
          }
          this.checkRestoreScroll();
          this._firstRender = true;
        }
      }
    }

    /*
     * Restore the scroll position on "history.back"
     */
    checkRestoreScroll() {
      if (this.restoreScroll) {
        const offset = this.memoryScroll[this.offsetKey];
        if (this.state.next && offset) {
          this.doScroll(offset);
          this.restoreEstimatedHeight();
        }
      }
    }

    /*
     * Calculate and store the estimated height of each element
     */
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      try {
        const index = this._radar.firstVisibleIndex;
        const movementsHeight = this._radar.getOffsetForIndex(index);
        const estimateHeight = movementsHeight / index;
        if (isFinite(estimateHeight)) {
          this.memoryScroll[this.estimateHeightKey] = estimateHeight;
          const averageScroll = (this._radar._scrollTop + this.memoryScroll[this.offsetKey]) / 2;
          this.memoryScroll[this.offsetKey] = averageScroll;
        }
      } catch {
        // it does not save in memory when firstVisibleIndex is not exist
        return;
      }
    }
    doScroll(top) {
      const scrolling = this._radar._scrollContainer;
      if (this._radar._scrollContainer) {
        if (scrolling.scrollTo) {
          scrolling.scrollTo(0, top);
        } else {
          scrolling.scrollTop = top;
        }
      }
    }
    restoreEstimatedHeight() {
      if (!this.SUPPORT_SCROLL_ANCHORING) {
        const offset = this.memoryScroll[this.offsetKey];
        const estimateHeight = this.memoryScroll[this.estimateHeightKey];
        const bufferSize = Math.max(Math.round(offset / estimateHeight), this.bufferSize);
        this.bufferSize = bufferSize;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "memoryScroll", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_bufferSize", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_estimateheight", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _class);
});