define("@woody-lite/core-session/components/modal-access-password", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/service", "@ember/object", "@woody-lite/core-session/templates/modal-access-password", "@glimmer/tracking", "ember"], function (_exports, _modal, _service, _object, _modalAccessPassword, _tracking, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DEFAULT_ALERT = 4000;

  // eslint-disable-next-line ember/no-ember-testing-in-module-scope
  if (_ember.default.testing) {
    DEFAULT_ALERT = 1;
  }
  const DEFAULT_IMAGE = 'banners/clave_acceso.svg';
  const ERROR_IMAGE = 'banners/clave_acceso_error.svg';
  let ModalAccessPassword = _exports.default = (_class = class ModalAccessPassword extends _modal.default {
    constructor() {
      var _this$veil;
      super(...arguments);

      // TODO: Remove into DB-230740
      _initializerDefineProperty(this, "alerter", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "veil", _descriptor3, this);
      _initializerDefineProperty(this, "image", _descriptor4, this);
      _initializerDefineProperty(this, "key", _descriptor5, this);
      _defineProperty(this, "errorImage", ERROR_IMAGE);
      _defineProperty(this, "layout", _modalAccessPassword.default);
      if ((_this$veil = this.veil) !== null && _this$veil !== void 0 && _this$veil.show) {
        this.veil.remove();
      }
    }
    didOpen() {
      super.didOpen(...arguments);
      this.element.querySelector('[data-id="txtSignatureAccess"] input').focus();
    }
    async submit() {
      const options = (0, _object.get)(this, 'model.options');
      const key = (0, _object.get)(this, 'key');
      const resource = options.resource;

      // Get promise.
      if (options.factory === 'ENAX') {
        (0, _object.set)(resource, 'headers.authenticationdata', "".concat(options.data, "=").concat(key));
      }
      try {
        this.veil.add();
        const data = await resource.retry();
        return this.close(data);
      } catch (e) {
        if (e !== null && e !== void 0 && e.closeLevelChange) {
          return this.reject(e);
        }
        const error = (e === null || e === void 0 ? void 0 : e.alert) || 'Error';
        this.image = this.errorImage;
        return this.alerter.add({
          text: error,
          type: 'float',
          visualType: 'error',
          accesibilityText: error,
          duration: DEFAULT_ALERT
        });
      } finally {
        this.key = '';
        this.veil.remove();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "alerter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "image", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_IMAGE;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "key", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _class);
});