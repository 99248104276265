define("@woody-lite/core-traceability/metrics-adapters/tealium", ["exports", "@glimmer/tracking", "@ember/object", "@ember/debug", "@ember/application", "@ember/service", "@ember/utils", "ember-copy", "ember-utils/utils/primitive/merge", "@woody-lite/core-traceability/utils/get-adapter-config", "@woody-lite/core-traceability/utils/get-base-object", "@woody-lite/core-traceability/utils/is-adapter-enabled", "@woody-lite/core-traceability/utils/is-adapter-in-dryRun", "@woody-lite/core-traceability/utils/load-script", "@ember/runloop"], function (_exports, _tracking, _object, _debug, _application, _service, _utils, _emberCopy, _merge, _getAdapterConfig2, _getBaseObject, _isAdapterEnabled, _isAdapterInDryRun, _loadScript, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.removeFromDOM = removeFromDOM;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  /* eslint-disable ember/classic-decorator-hooks */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const RETRY_DIGEST_MAX = 10;
  const RETRY_DIGEST_TIMEOUT = 50;
  const LOGGING_HEADER = '[@woody-lite/core-traceability]';
  function getEventContext(event) {
    const {
      app: _app,
      session: _session,
      currentRoute: _currentRoute,
      multiStep: _multiStep,
      cookiesConsent: _cookiesConsent
    } = event.tealiumContext;
    return {
      app: _app ? (0, _emberCopy.copy)(_app, true) : null,
      session: _session ? (0, _emberCopy.copy)(_session, true) : null,
      currentRoute: _currentRoute ? (0, _emberCopy.copy)(_currentRoute, true) : null,
      multiStep: _multiStep ? (0, _emberCopy.copy)(_multiStep, true) : null,
      cookiesConsent: _cookiesConsent ? (0, _emberCopy.copy)(_cookiesConsent, true) : null
    };
  }
  function removeFromDOM(selectors) {
    document.querySelectorAll(selectors).forEach(el => {
      var _el$parentElement;
      (_el$parentElement = el.parentElement) === null || _el$parentElement === void 0 ? void 0 : _el$parentElement.removeChild(el);
    });
  }
  function timeout(time) {
    return new Promise(resolve => (0, _runloop.later)(this, resolve, time));
  }
  let TealiumAdapter = _exports.default = (_class = class TealiumAdapter extends _object.default {
    get maxRetriesExhausted() {
      return this._retryCount >= RETRY_DIGEST_MAX;
    }
    constructor(configuration, owner) {
      var _getAdapterConfig;
      super(...arguments);
      _initializerDefineProperty(this, "scheduler", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _defineProperty(this, "_retryCount", 0);
      _initializerDefineProperty(this, "eventQueue", _descriptor3, this);
      _initializerDefineProperty(this, "appendQueue", _descriptor4, this);
      _defineProperty(this, "laterTimer", void 0);
      _defineProperty(this, "metrics", null);
      _defineProperty(this, "config", null);
      (0, _application.setOwner)(this, owner);
      this.config = configuration;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const adapterConfig = (_getAdapterConfig = (0, _getAdapterConfig2.default)(config, 'tealium')) === null || _getAdapterConfig === void 0 ? void 0 : _getAdapterConfig.config;
      const addonConfig = config['core-traceability'];
      this._scriptUrl = addonConfig === null || addonConfig === void 0 ? void 0 : addonConfig.scriptUrl;
      this._asyncScriptUrl = addonConfig === null || addonConfig === void 0 ? void 0 : addonConfig.asyncScriptUrl;
      this._normalizerName = (addonConfig === null || addonConfig === void 0 ? void 0 : addonConfig.tealiumVersion) || 'canonical';
      this._versionNormalizer = (0, _application.getOwner)(this).lookup("normalizer:".concat(this._normalizerName));
      this.dryRun = !(0, _isAdapterEnabled.default)(config) || (0, _isAdapterInDryRun.default)(config);
      this.enableDebug = adapterConfig === null || adapterConfig === void 0 ? void 0 : adapterConfig.debug;
      if (!this.dryRun) {
        (0, _loadScript.default)(this._scriptUrl);
        (0, _loadScript.default)(this._asyncScriptUrl, 'head');
      }
      window.digitalData = {};
    }
    trackEvent(event) {
      if (!(this.isDestroying || this.isDestroyed)) {
        this.scheduler.schedule(this, 'pushEvent', event);
      }
    }
    pushEvent(event) {
      this.debug('Event added to queue', event);
      if (event) {
        this.eventQueue = [...this.eventQueue, event];
      }
      this.updateEventQueue();
      this.digestTask();
    }
    async digestTask() {
      this.debug("Digest event queue (".concat(this.eventQueue.length, " elements)"));
      this._retryCount = 0;
      while (!this.maxRetriesExhausted && !this._digest()) {
        this._retryCount++;
        await timeout(RETRY_DIGEST_TIMEOUT);
      }
    }
    _digest() {
      var _this$eventQueue;
      if (!((_this$eventQueue = this.eventQueue) !== null && _this$eventQueue !== void 0 && _this$eventQueue.length)) {
        return true;
      }
      if (this.dryRun || typeof window.digitalChange === 'function') {
        this.eventQueue.forEach(this.scheduler.schedule.bind(this.scheduler, this, 'sendEvent'));
        this.eventQueue = [];
        return true;
      }
      (false && (0, _debug.warn)("".concat(LOGGING_HEADER, " unable to digest! retry in ").concat(RETRY_DIGEST_TIMEOUT, "ms"), {
        id: 'woody-lite.core-traceability.missing-digital-change-fn'
      }));
      return false;
    }
    sendEvent(event) {
      // istanbul ignore else: No necessary test when tealium is isDestroying or isDestroyed
      if (!(this.isDestroying || this.isDestroyed)) {
        let payload = event === null || event === void 0 ? void 0 : event.payload;
        if (event !== null && event !== void 0 && event.tealiumContext) {
          const {
            app,
            session,
            currentRoute,
            multiStep,
            cookiesConsent
          } = getEventContext(event);
          payload = (0, _merge.default)({}, app, session, currentRoute, multiStep, cookiesConsent, event.payload);
        }
        this.send(event.type, payload);
        return;
      }
    }
    append(options) {
      const context = Object.keys(options)[0];
      delete options[context];
      this.appendQueue = [...this.appendQueue, options];
    }
    updateEventQueue() {
      var _this$eventQueue2, _this$appendQueue;
      if ((_this$eventQueue2 = this.eventQueue) !== null && _this$eventQueue2 !== void 0 && _this$eventQueue2.length && (_this$appendQueue = this.appendQueue) !== null && _this$appendQueue !== void 0 && _this$appendQueue.length) {
        var _this$eventQueue3;
        const lastEvent = (_this$eventQueue3 = this.eventQueue[this.eventQueue.length - 1]) === null || _this$eventQueue3 === void 0 ? void 0 : _this$eventQueue3.payload;
        this.appendQueue.forEach(appendBlock => {
          (0, _merge.default)(lastEvent, appendBlock);
        });
        this.appendQueue = [];
      }
    }
    send(type, payload) {
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          const value = payload[key];
          if (!(0, _utils.isEmpty)(value)) {
            window.digitalData[key] = value;
          }
        }
      }

      // Complete empty fields
      window.digitalData = (0, _merge.default)((0, _getBaseObject.default)(), window.digitalData);
      this.debug('`canonical object` params:', type, window.digitalData);
      if (!this.dryRun) {
        const normalizedData = this._versionNormalizer.normalize(window.digitalData);
        this.debug("`sending ".concat(this._normalizerName, " object` params:"), type, normalizedData);
        window.digitalChange(type, normalizedData);
      }
      window.digitalData = {};
    }
    willDestroy() {
      this.eventQueue.splice(0, this.eventQueue.length);
      removeFromDOM("script[src=\"".concat(this._scriptUrl, "\"]"));
      delete window.digitalData;
    }
    debug(message) {
      if (this.enableDebug) {
        for (var _len = arguments.length, groupValues = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          groupValues[_key - 1] = arguments[_key];
        }
        this.debugIntoConsole(message, ...groupValues);
      }
    }
    debugIntoConsole(message) {
      for (var _len2 = arguments.length, groupValues = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        groupValues[_key2 - 1] = arguments[_key2];
      }
      (0, _debug.runInDebug)(() => {
        if (groupValues !== null && groupValues !== void 0 && groupValues.length) {
          console.groupCollapsed("".concat(LOGGING_HEADER, " ").concat(message));
          groupValues.forEach(value => {
            console.debug(value);
          });
          console.groupEnd();
        } else {
          console.debug("".concat(LOGGING_HEADER, " ").concat(message));
        }
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "scheduler", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "eventQueue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "appendQueue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _class);
});