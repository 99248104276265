define("@woody-lite/core-session/utils/get-session", ["exports", "@woody-lite/core-session/models/session/base", "@ember/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getSession;
  function getSession(store, app) {
    const currentSession = (0, _object.get)(store, 'content').find(model => model instanceof _base.default && (0, _object.get)(model, 'app') === app);
    if (!currentSession) {
      return currentSession;
    }
    return currentSession.isSynchronized ? currentSession : undefined;
  }
});