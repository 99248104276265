define("@woody-lite/core-traceability/utils/is-adapter-enabled", ["exports", "@woody-lite/core-traceability/utils/get-adapter-config"], function (_exports, _getAdapterConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isAdapterEnabled;
  function isAdapterEnabled(config) {
    let adapterName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'tealium';
    const tealiumAdapterConfig = (0, _getAdapterConfig.default)(config, adapterName);
    if (!tealiumAdapterConfig) {
      return false;
    }
    const adapterEnvs = tealiumAdapterConfig.environments || ['all'];
    const activeEnvs = [config.environment, 'all'];
    return adapterEnvs.some(env => activeEnvs.includes(env));
  }
});