define("@woody-lite/core-semaas/models/measurement", ["exports", "@ember/object", "@ember/utils", "@woody-lite/core-semaas/constants"], function (_exports, _object, _utils, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function getPresentKeys(object) {
    const copy = {};
    for (const [key, value] of Object.entries(object)) {
      if ((0, _utils.isPresent)(value)) {
        copy[key] = value;
      }
    }
    return copy;
  }
  class Measurement extends _object.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "offset", 0);
      _defineProperty(this, "properties", {});
      _defineProperty(this, "values", {});
      (0, _object.set)(this, 'timestamp', this.getTimestamp());
      this.serializeProperties = ['timestamp', 'properties', 'values'];
    }
    getTimestamp() {
      const time = Date.now() * _constants.MILLISECONDS_TO_NANO;
      return time + this.offset;
    }
    toJSON() {
      const {
        serializeProperties
      } = this;
      // eslint-disable-next-line ember/classic-decorator-no-classic-methods
      let object = (0, _object.getProperties)(this, serializeProperties);
      object = getPresentKeys(object);
      for (const [key, value] of Object.entries(object)) {
        if ((0, _utils.typeOf)(value) === 'object') {
          object[key] = getPresentKeys(value);
        }
      }
      return object;
    }
  }
  _exports.default = Measurement;
});