define("@woody-lite/core-session/errors/login-go-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LoginGoOnError extends Error {
    constructor(_ref) {
      let {
        authenticationData,
        authenticationState,
        authenticationType,
        consumerId,
        multistepProcessId,
        username
      } = _ref;
      super('Login authentication needs user interaction');
      this.name = 'LoginGoOnError';
      this.authenticationData = authenticationData;
      this.authenticationState = authenticationState;
      this.authenticationType = authenticationType;
      this.consumerId = consumerId;
      this.multistepProcessId = multistepProcessId;
      this.username = username;
    }
  }
  _exports.default = LoginGoOnError;
});