define("@woody-lite/core-session/decorators/-session-inactivity", ["exports", "@ember/debug", "@ember/object", "@ember/service", "@woody-lite/core-session/models/session/base"], function (_exports, _debug, _object, _service, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sessionInactivity;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const TIMER_ID = 'inactivity';
  function sessionInactivity(timeout) {
    (false && !(typeof timeout === 'number') && (0, _debug.assert)("The 'sessionInactivity' decorator needs a number as max timeout", typeof timeout === 'number'));
    return function (target) {
      var _class, _descriptor;
      (false && !(_base.default.detect(target)) && (0, _debug.assert)("The 'authenticated' decorator must be applied to a Session model", _base.default.detect(target)));
      const klass = (_class = class _class extends target {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "provider", _descriptor, this);
        }
        _createTimers() {
          super._createTimers(...arguments);
          this._createTimer({
            id: TIMER_ID,
            callback: this._onInactivityTimeout,
            delay: timeout
          });
        }
        async _onUserInteraction() {
          await this.getTimer(TIMER_ID).run();
        }
        async _runTimers() {
          await super._runTimers(...arguments);
          const device = await this.provider.pull('device', 'getDevice');
          device.on('user-interaction', this, '_onUserInteraction');
        }
        async _clearTimers() {
          await super._clearTimers(...arguments);
          const device = await this.provider.pull('device', 'getDevice');
          device.off('user-interaction', this, '_onUserInteraction');
        }
        _onInactivityTimeout() {
          if (!(this.isDestroying || this.isDestroyed)) {
            this.logout();
          }
        }
      }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _applyDecoratedDescriptor(_class.prototype, "_onInactivityTimeout", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onInactivityTimeout"), _class.prototype), _class);
      Object.defineProperty(klass, 'name', {
        value: target.name
      });
      return klass;
    };
  }
});