define("liquid-fire/helpers/lf-lock-model", ["exports", "@ember/component/helper", "liquid-fire/ember-internals"], function (_exports, _helper, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lfLockModel = lfLockModel;
  function lfLockModel(_ref) {
    let [routeInfo, outletName] = _ref;
    // ensures that the name is locked, see implementation of `routeModel`
    (0, _emberInternals.routeModel)((0, _emberInternals.childRoute)(routeInfo, outletName));
    return routeInfo;
  }
  var _default = _exports.default = (0, _helper.helper)(lfLockModel);
});