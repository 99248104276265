define("@woody-lite/core-session/utils/is-descriptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isDescriptor;
  const descriptorLength = 3;
  function isDescriptor() {
    for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
      params[_key] = arguments[_key];
    }
    const [target, key, desc] = params;
    return params.length === descriptorLength && typeof target === 'object' && target !== null && typeof key === 'string' && typeof desc === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc;
  }
});