define("@woody-mrs-potato/core-storages/storages/base", ["exports", "@ember/object/proxy", "@ember/utils", "@ember/array", "@ember/object"], function (_exports, _proxy, _utils, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SEPARATOR = ':';
  class BaseStorage extends _proxy.default {
    init() {
      super.init(...arguments);
      this._addStorageListener();
    }
    isKeySerialized(key) {
      return key.split(SEPARATOR).length > 1;
    }
    serializeKey(key) {
      const namespace = this.namespace;
      return this.isKeySerialized(key) ? key : "".concat(namespace).concat(SEPARATOR).concat(key);
    }
    deserializeKey(serializedKey) {
      const parts = serializedKey.split(SEPARATOR);
      const namespace = parts.length === 1 ? '' : parts[0];
      const key = parts.length === 2 ? parts[1] : parts[0];
      return {
        namespace,
        key
      };
    }
    unknownProperty(key) {
      key = this.serializeKey(key);
      return super.unknownProperty(key);
    }
    setUnknownProperty(key, value) {
      key = this.serializeKey(key);
      super.setUnknownProperty(key, value);

      // Delete from memory if not exists.
      if ((0, _utils.isNone)(value)) {
        this._delete(key);
      } else {
        this._save(key);
      }
    }
    set() {
      return this.setUnknownProperty(...arguments);
    }
    setProperties(obj) {
      this.beginPropertyChanges();
      Object.keys(obj).forEach(key => {
        const value = obj[key];
        key = this.serializeKey(key);
        this.setUnknownProperty(key, value);
      });
      this.endPropertyChanges();
    }
    clear() {
      const content = (0, _object.get)(this, 'content');
      Object.keys(content).forEach(key => {
        this._delete(key);
      });
    }
    keys() {
      const content = (0, _object.get)(this, 'content');
      const keys = Object.keys(content).map(key => {
        const keyData = this.deserializeKey(key);
        return keyData.key;
      });
      return (0, _array.A)(keys);
    }
    _addStorageListener() {
      this._storageEventHandler = this.handleStorageEvent.bind(this);
      window.addEventListener('storage', this._storageEventHandler);
    }
    willDestroy() {
      if (this._storageEventHandler) {
        window.removeEventListener('storage', this._storageEventHandler, false);
      }
      super.willDestroy(...arguments);
    }
    handleStorageEvent() {
      return this;
    }
    _save() {
      return this;
    }
    _delete(key) {
      delete this.content[key];
    }
  }
  _exports.default = BaseStorage;
});