define("@woody-mrs-potato/addon-ember-modal-service/utils/css-transitions/index", ["exports", "@woody-mrs-potato/addon-ember-modal-service/utils/css-transitions/has-transitions"], function (_exports, _hasTransitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "hasTransitions", {
    enumerable: true,
    get: function () {
      return _hasTransitions.default;
    }
  });
});