define("@woody-lite/engine-cards/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function serializeCard(model) {
    return {
      card_id: model.id
    };
  }
  function serializeMovement(model) {
    return {
      movement_id: model.id
    };
  }
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('overview', function () {
      this.route('contract');
      this.route('card', {
        path: '/:card_id',
        serialize: serializeCard
      });
    });
    this.route('card', {
      path: '/:card_id',
      serialize: serializeCard
    }, function () {
      this.route('finder');
      this.route('more-info');
      this.route('movement', {
        path: '/:movement_id',
        serialize: serializeMovement
      });
      this.route('security');
      this.route('view-statement', function () {
        this.route('detail');
        this.route('statement-movements');
        this.route('no-statement');
      });
    });
  });
});