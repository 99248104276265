define("@woody-mrs-potato/core-ember-cli-ifa/utils/get-asset-map-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAssetMapData;
  function getAssetMapData() {
    let metaTag = document.querySelector("meta[name='ember-cli-ifa:assetMap']");
    if (!metaTag) {
      console.warn('<meta name="ember-cli-ifa:assetMap"> tag is missing.');
      return;
    }
    const assetMapString = metaTag.content;
    if (assetMapString) {
      return JSON.parse(decodeURIComponent(assetMapString));
    }
  }
});