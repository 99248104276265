define("@woody-mrs-potato/addon-slinky/utils/guid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = guid;
  const GUID_SIZE = 16;
  const HEX_BASE = 16;
  function guid() {
    const values = crypto.getRandomValues(new Uint8Array(GUID_SIZE));
    return Array.from(values).map(value => value.toString(HEX_BASE).padStart(2, '0')).join('');
  }
});