define("@woody-lite/core-traceability/normalizers/canonical", ["exports", "@ember/object", "ember-copy"], function (_exports, _object, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CanonicalNormalizer extends _object.default {
    normalize(eventData) {
      const data = (0, _emberCopy.copy)(eventData, true);
      data.versionDL = 'canonical';
      return data;
    }
  }
  _exports.default = CanonicalNormalizer;
});