define("@woody-lite/engine-account-transfer/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('index', {
      path: '/'
    });
    this.route('new', function () {
      this.route('index');
      this.route('summary');
      this.route('success');
    });
    this.route('overview', function () {
      this.route('index');
    });
    this.route('incidents', function () {
      this.route('index', {
        path: '/:request_id'
      });
      this.route('incident', {
        path: '/incident/:id'
      });
      this.route('confirmation');
    });
  });
});