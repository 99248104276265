define("liquid-fire/mixins/pausable", ["exports", "rsvp", "@ember/object/evented", "@ember/service", "@ember/object/mixin"], function (_exports, _rsvp, _evented, _service, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    _transitionMap: (0, _service.inject)('liquid-fire-transitions'),
    _initializeLiquidFirePauseable: (0, _evented.on)('init', function () {
      this._lfDefer = [];
    }),
    pauseLiquidFire() {
      const context = this.nearestWithProperty('_isLiquidChild');
      if (context) {
        let def = new _rsvp.defer();
        let tmap = this._transitionMap;
        tmap.incrementRunningTransitions();
        def.promise.finally(() => tmap.decrementRunningTransitions());
        this._lfDefer.push(def);
        context._waitForMe(def.promise);
      }
    },
    resumeLiquidFire: (0, _evented.on)('willDestroyElement', function () {
      let def = this._lfDefer.pop();
      if (def) {
        def.resolve();
      }
    })
  });
});