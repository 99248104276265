define("@woody-lite/addon-conversations/normalizers/thread-message", ["exports", "@ember/object", "@ember/array"], function (_exports, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThreadMessageNormalizer extends _object.default {
    normalize(properties) {
      properties.text = properties.content; // fix object proxy
      properties.hasAttachments = (0, _array.isArray)(properties.attachments);
      delete properties.content;
      return {
        name: 'thread-message',
        properties
      };
    }
  }
  _exports.default = ThreadMessageNormalizer;
});