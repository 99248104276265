define("@woody-lite/core-traceability/instance-initializers/tealium-modal-tracking", ["exports", "@woody-lite/core-traceability/utils/get-page-levels", "@woody-lite/core-traceability/utils/page-name", "@woody-lite/core-traceability/utils/tealium-context", "@woody-lite/core-traceability/utils/is-adapter-enabled", "@woody-lite/core-traceability/events/view"], function (_exports, _getPageLevels, _pageName2, _tealiumContext, _isAdapterEnabled, _view) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function getModalTrackingData(metrics, models, model) {
    var _model$options;
    const modalPageLevels = models.map(_ref => {
      let {
        fullname
      } = _ref;
      return (0, _getPageLevels.default)(fullname);
    }).flat();
    const pageInfo = {
      ...(0, _tealiumContext.getContext)(metrics, 'currentRoute.page.pageInfo')
    };
    const area = (0, _tealiumContext.getContext)(metrics, 'session.page.pageInfo.area');

    /*
     * Complete the `level`s and `pageName` properties by appending the modal name at the end.
     * Current route context for Tealium is used if available.
     */
    const routeLevels = Object.keys(pageInfo).filter(key => key.startsWith('level'));
    let lastRouteLevel = 0;
    if (routeLevels.length) {
      lastRouteLevel = parseInt(routeLevels.sort().reverse()[0].replace('level', ''), 10);
    }
    if (lastRouteLevel < _getPageLevels.MAX_PAGE_LEVELS) {
      modalPageLevels.slice(0, _getPageLevels.MAX_PAGE_LEVELS - lastRouteLevel).forEach((level, index) => {
        pageInfo["level".concat(lastRouteLevel + index + 1)] = level;
      });
    }
    const {
      pageName: _pageName
    } = pageInfo;
    pageInfo.pageName = _pageName ? "".concat(_pageName, ":").concat(modalPageLevels.join(':')) : (0, _pageName2.default)(area, ...modalPageLevels);
    return {
      page: {
        pageInfo
      },
      ...((_model$options = model.options) === null || _model$options === void 0 ? void 0 : _model$options.trackingData)
    };
  }
  function setupModalListener(metrics, modal, scheduler) {
    const callback = scheduler.scheduleOnce.bind(scheduler, this, (models, model) => {
      const trackingData = getModalTrackingData(metrics, models, model);
      metrics.trackEvent('tealium', new _view.default(trackingData));
    }, modal.content);
    modal.on('open', callback);
  }
  function initialize(instance) {
    const config = instance.resolveRegistration('config:environment');
    if ((0, _isAdapterEnabled.default)(config)) {
      // This condition prevents initializing the adapter
      const scheduler = instance.lookup('service:scheduler');
      const modal = instance.lookup('service:modal');
      const metrics = instance.lookup('service:metrics');
      if (metrics && modal) {
        setupModalListener(metrics, modal, scheduler);
      }
    }
  }
  var _default = _exports.default = {
    name: 'tealium/modal-tracking',
    initialize
  };
});