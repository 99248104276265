define("@woody-lite/core-traceability/services/ab-testing", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AbTesting extends _service.default {
    sendNotification(mbox) {
      let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'display';
      if (window.ATManager) {
        window.ATManager.sendNotification(mbox, type);
      }
    }
    async getExperience(mbox) {
      try {
        const abTests = await this._loadABTests();
        if (mbox && abTests && abTests[mbox]) {
          return abTests[mbox];
        }
      } catch {
        // Noop
      }
      return null;
    }
    _loadABTests() {
      return window.ATManager.retrieveOffers();
    }
  }
  _exports.default = AbTesting;
});