define("ember-lazy-mount/services/engine-loader", ["exports", "@ember/application", "@ember/service", "require"], function (_exports, _application, _service, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    /**
     * Checks the owner to see if it has a registration for an Engine. This is a
     * proxy to tell if an Engine's assets are loaded or not.
     *
     * @see https://github.com/ember-engines/ember-engines/blob/8f66b5e3b8089cd53884be49f270cac05f9a3d17/addon/-private/router-ext.js#L152-L164
     *
     * @param {String} name
     * @return {Boolean}
     */
    isLoaded(name) {
      const owner = (0, _application.getOwner)(this);
      return owner.hasRegistration("engine:".concat(name));
    },
    /**
     * Registers an Engine that was recently loaded.
     *
     * @see https://github.com/ember-engines/ember-engines/blob/8f66b5e3b8089cd53884be49f270cac05f9a3d17/addon/-private/router-ext.js#L166-L182
     *
     * @param {String} name
     */
    register(name) {
      if (this.isLoaded(name)) return;
      const owner = (0, _application.getOwner)(this);
      owner.register("engine:".concat(name), (0, _require.default)("".concat(name, "/engine")).default);
    },
    /**
     * Loads and registers a lazy Engine.
     *
     * @param {String} name
     * @async
     */
    async load(name) {
      if (this.isLoaded(name)) return;
      const assetLoader = (0, _application.getOwner)(this).lookup('service:asset-loader');
      await assetLoader.loadBundle(name);
      this.register(name);
    }
  });
});