define("@woody-mrs-potato/addon-slinky/core/slinky", ["exports", "@woody-mrs-potato/addon-slinky/utils/guid", "@woody-mrs-potato/addon-slinky/utils/event-emitter", "@woody-mrs-potato/addon-slinky/errors/slinky", "@woody-mrs-potato/addon-slinky/errors/timeout-expired"], function (_exports, _guid, _eventEmitter, _slinky, _timeoutExpired) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let instance = null;
  function makeCallback(instance, event, slinkyRequestId) {
    if (slinkyRequestId) {
      return "window['".concat(instance, "'] && window['").concat(instance, "'].trigger('").concat(event, "', $error, $data, $slinkyRequestId);");
    }
    return "window['".concat(instance, "'] && window['").concat(instance, "'].trigger('").concat(event, "', $error, $data);");
  }
  function promiseCallback(resolve, reject, timeout) {
    if (timeout) {
      const originalResolve = resolve;
      const timer = setTimeout(() => reject(new _timeoutExpired.default()), timeout);
      resolve = function () {
        clearTimeout(timer);
        return originalResolve(...arguments);
      };
    }
    return (err, data, slinkyRequestId) => {
      if (err) {
        reject(err);
      } else {
        const composedData = {
          ...data,
          ...(slinkyRequestId && {
            slinkyRequestId
          })
        };
        resolve(composedData);
      }
    };
  }
  class Slinky {
    constructor() {
      _defineProperty(this, "id", "slinky_".concat((0, _guid.default)()));
      _defineProperty(this, "wire", void 0);
      _defineProperty(this, "emitter", new _eventEmitter.default());
      _defineProperty(this, "events", {});
      if (instance !== null) {
        return instance;
      }
      instance = this;
      window[this.id] = this;

      // defensive programming
      window.$slinkyRequestId = false;
    }
    destroy() {
      this.emitter.removeAllListeners();
      instance = null;
      delete window[this.id];
    }
    register(wire) {
      if (wire.isEnabled) {
        this.wire = wire;
      }
    }
    unregister(wire) {
      if (this.wire === wire) {
        this.wire = undefined;
      }
    }
    get isConnected() {
      return Boolean(this.wire);
    }
    send(event, data, slinkyRequestId, timeout) {
      return new Promise((resolve, reject) => {
        if (typeof this.wire === 'undefined') {
          throw new Error('Slinky is not connected to any wire');
        }
        const eventId = "".concat(event, "-").concat((0, _guid.default)());
        const message = {
          data,
          callback: makeCallback(this.id, eventId, slinkyRequestId),
          slinkyRequestId
        };
        this.emitter.once(eventId, promiseCallback(resolve, reject, timeout));
        try {
          var _this$wire;
          (_this$wire = this.wire) === null || _this$wire === void 0 ? void 0 : _this$wire.send(event, message);
        } catch (e) {
          reject(e);
        }
      });
    }
    async on(event, data, callback) {
      if (!callback) {
        callback = data;
        data = undefined;
      }
      if (typeof callback == 'undefined') {
        throw new Error("Cannot register an event with no callback for event '".concat(event, "'"));
      }
      const eventId = "".concat(event, "-").concat((0, _guid.default)());
      const message = {
        ...data,
        event,
        callback: makeCallback(this.id, eventId)
      };
      this.events[eventId] = callback;
      this.emitter.addListener(eventId, callback);
      return this.send('Event.register', message);
    }
    async off(event, callback) {
      if (typeof callback === 'undefined') {
        throw new Error("Cannot unregister an event with no callback for event '".concat(event, "'"));
      }
      const [eventId] = Object.entries(this.events).find(_ref => {
        let [, fn] = _ref;
        return fn === callback;
      }) || [];
      if (typeof eventId === 'undefined') {
        throw new Error("Cannot unregister an unregistered callback for event '".concat(event, "'"));
      }
      const message = {
        event,
        callback: makeCallback(this.id, eventId)
      };
      await this.send('Event.unregister', message);
      delete this.events[eventId];
      this.emitter.removeListener(eventId, callback);
    }
    trigger(eventId, error, data, slinkyRequestId) {
      if (!this.emitter.listenerCount(eventId)) {
        throw new Error("Slinky instance '".concat(this.id, "' has no listeners for '").concat(eventId, "'"));
      }
      if (error) {
        var _eventId$match;
        const event = (_eventId$match = eventId.match(/[^-]+/)) === null || _eventId$match === void 0 ? void 0 : _eventId$match[0];
        error = new _slinky.default(Object.assign({
          event
        }, error));
      }
      this.emitter.emit(eventId, error, data, slinkyRequestId);
    }
  }
  _exports.default = Slinky;
});