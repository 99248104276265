define("@woody-lite/core-session/errors/signature-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SignatureSelectionError = void 0;
  class SignatureSelectionError extends Error {
    constructor() {
      super(...arguments);
      this.name = this.constructor.name;
    }
  }
  _exports.SignatureSelectionError = SignatureSelectionError;
});