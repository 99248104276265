define("@woody-lite/core-session/errors/safetap-technical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SafeTapTechnicalError = void 0;
  class SafeTapTechnicalError extends Error {
    constructor() {
      super(...arguments);
      this.name = this.constructor.name;
    }
  }
  _exports.SafeTapTechnicalError = SafeTapTechnicalError;
});