define("@woody-lite/core-traceability/services/rocky", ["exports", "@ember/service", "@ember/object", "@ember/application"], function (_exports, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const MNTUD = 'https://d19m7cewwe0ip8.cloudfront.net/{snippetId}/mntud.js';
  const FRAMED = 'https://d19m7cewwe0ip8.cloudfront.net/{snippetId}/framed.js';
  let RockyService = _exports.default = (_class = class RockyService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      this.config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }
    async isRockyActive() {
      const publicConfig = await this.provider.pull('configuration', 'getPublicConfiguration');
      return (0, _object.get)(publicConfig, 'rockyScript.isActive');
    }
    setDt(dt) {
      this.dt = dt;
    }
    async loadRocky(dt) {
      const dtValue = dt || this.dt;
      const rockyAvailable = await this.isRockyActive();
      const snippetId = (0, _object.get)(this.config, 'rocky.snippetId');
      if (!rockyAvailable) {
        return;
      }
      if (dtValue && window.loadScript) {
        await this.loadScriptOrSignal(MNTUD, dtValue, snippetId);
        if (dtValue === 'login') {
          await this.loadScriptOrSignal(FRAMED, dtValue, snippetId);
        }
      }
    }
    replaceSnippetId(scriptName, snippetId) {
      return scriptName.replace('{snippetId}', snippetId);
    }
    async loadScriptOrSignal(scriptName, dtValue, snippetId) {
      if (!window.trustLoaded) {
        window.trustLoaded = {};
      }
      const script = this.replaceSnippetId(scriptName, snippetId);
      if (window.trustLoaded[script]) {
        this.sendSignal(dtValue);
      } else {
        window.trustLoaded[script] = true;
        await window.loadScript(script, dtValue, snippetId);
      }
    }
    setStlgvalValue(step, values) {
      if (window.stlgval) {
        window.stlgval(step, values);
      }
    }
    sendSignal(signal) {
      if (window.valrun) {
        window.valrun(signal);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});