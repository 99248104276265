define("@woody-mrs-potato/addon-ember-modal-service/utils/css-transitions/has-transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasTransitions;
  function hasTransitions(element) {
    const {
      transitionProperty,
      transitionDuration
    } = window.getComputedStyle(element);
    const hasNoDurations = transitionDuration.split(', ').every(duration => duration === '0s');
    return !(hasNoDurations || transitionProperty === 'all' && transitionDuration === '0s');
  }
});