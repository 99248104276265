define("ember-collector-dispatcher/storage-adapters/memory", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Memory extends _object.default {
    init() {
      super.init(...arguments);
      this.memory = [];
    }
    async isSupported() {
      return true;
    }
    async count() {
      return this.memory.length;
    }
    async push() {
      for (var _len = arguments.length, items = new Array(_len), _key = 0; _key < _len; _key++) {
        items[_key] = arguments[_key];
      }
      items.forEach(item => this.memory.push(item));
    }
    async unshift() {
      for (var _len2 = arguments.length, items = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        items[_key2] = arguments[_key2];
      }
      items.forEach(item => this.memory.unshift(item));
    }
    async pop() {
      let count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      return this.memory.splice(-count);
    }
    async shift() {
      let count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      return this.memory.splice(0, count);
    }
  }
  _exports.default = Memory;
});