define("@woody-lite/core-session/repositories/valid-cards", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/repositories/repository", "@ember/service", "@woody-mrs-potato/addon-ember-object-data-model/models/base-array", "@ember/object"], function (_exports, _repository, _service, _baseArray, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ValidCardsRepository = _exports.default = (_class = class ValidCardsRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
    }
    async getValidCards() {
      try {
        const cards = await this.provider.pull('cards', 'allCards');
        const validCards = await this.enax.GET('valid-cards');
        const indicators = this.normalizeIndicators(validCards);
        const content = cards.filter(card => {
          const id = (0, _object.get)(card, 'id');
          return indicators[id] && indicators[id].CHANNEL_OPERABLE && indicators[id].ACTIVE && !indicators[id].TEMPORAL_BLOCKED && !indicators[id].CONTACLESS_BLOCKED;
        });
        return _baseArray.default.create({
          content
        });
      } catch (e) {
        return _baseArray.default.create({
          content: []
        });
      }
    }
    normalizeIndicators(indicatorsData) {
      return Object.assign(...indicatorsData.map(card => {
        return {
          [card.contract.id]: Object.assign(...card.indicators.map(item => {
            return {
              [item.id]: item.isActive
            };
          }))
        };
      }));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});