define("@woody-lite/core-session/templates/modal-face-recognition-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "UgdfGxSt",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\t\"],[8,[30,1,[\"header\"]],null,[[\"@type\"],[\"modal-primary\"]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[30,2,[\"title\"]],null,[[\"@title\"],[[28,[37,1],[\"label.signature\"],null]]],null],[1,\"\\n\\t\\t\"],[8,[30,2,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,3,[\"icon\"]],[[24,\"data-id\",\"btnClose\"],[24,0,\"icon-close\"]],[[\"@onClick\",\"@accessibilityText\"],[[30,0,[\"closeModal\"]],[28,[37,1],[\"label.close\"],null]]],null],[1,\"\\n\\t\\t\"]],[3]]]]],[1,\"\\n\\t\"]],[2]]]]],[1,\"\\n\\t\"],[8,[30,1,[\"alerter\"]],null,null,null],[1,\"\\n\\n\\t\"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\\n\\t\\t\"],[8,[39,2],null,[[\"@image\",\"@size\",\"@title\"],[[28,[37,3],[\"banners/error.svg\"],null],\"small\",[28,[37,1],[[30,0,[\"title\"]]],null]]],[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,4,[\"text\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\\t\"],[1,[28,[35,1],[[30,0,[\"text\"]]],null]],[1,\"\\n\\t\\t\\t\"]],[]]]]],[1,\"\\n\\t\\t\"]],[4]]]]],[1,\"\\n\\n\\t\\t\"],[10,0],[14,0,\"set-padding-2 txt-align-c\"],[12],[1,\"\\n\\t\\t\\t\"],[8,[39,4],null,[[\"@text\",\"@onClick\"],[[28,[37,1],[\"label.close\"],null],[30,0,[\"closeModal\"]]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"wrapper\",\"header\",\"i\",\"panel\"],false,[\"wrapper-main\",\"t\",\"panels/panel-info\",\"image-path\",\"buttons/button-default\"]]",
    "moduleName": "@woody-lite/core-session/templates/modal-face-recognition-error.hbs",
    "isStrictMode": false
  });
});