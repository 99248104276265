define("@woody-lite/engine-easy-pay/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('finder');
    this.route('detail');
    this.route('forbidden');
    this.route('error', {
      path: '/error/:id'
    });
    this.route('insufficient-limit');
    this.route('cancel-disposal', function () {
      this.route('confirmation');
      this.route('error');
    });
    this.route('create', function () {
      this.route('cancel');
      this.route('error');
      this.route('summary');
      this.route('confirmation');
    });
    this.route('settings');
  });
});