define("@woody-mrs-potato/addon-ember-modal-service/models/modal", ["exports", "@ember/object/computed", "@ember/object", "@ember/string", "rsvp", "@ember/utils", "@ember/object/promise-proxy-mixin", "@glimmer/tracking"], function (_exports, _computed, _object, _string, _rsvp, _utils, _promiseProxyMixin, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  /* eslint-disable ember/no-computed-properties-in-native-classes */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ModalModel = _exports.default = (_dec = (0, _computed.oneWay)('_deferred.promise'), _dec2 = (0, _object.computed)('name'), _class = class ModalModel extends _object.default.extend(_promiseProxyMixin.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "name", null);
      _defineProperty(this, "options", null);
      _initializerDefineProperty(this, "isPending", _descriptor, this);
      _initializerDefineProperty(this, "isSettled", _descriptor2, this);
      _initializerDefineProperty(this, "isFulfilled", _descriptor3, this);
      _initializerDefineProperty(this, "isRejected", _descriptor4, this);
      _initializerDefineProperty(this, "_deferred", _descriptor5, this);
      _initializerDefineProperty(this, "promise", _descriptor6, this);
    } // Do not use "PromiseProxyMixin", "@oneWay" does not tracks the state.
    get fullname() {
      const name = this.name;
      if ((0, _utils.isBlank)(name)) {
        throw new Error('Modal must have a name.');
      }
      return "modal-".concat((0, _string.dasherize)(name));
    }
    init() {
      super.init(...arguments);
      this._deferred = (0, _rsvp.defer)("Modal: open '".concat(this.fullname, "'"));
    }
    resolve() {
      this.isPending = false;
      this.isSettled = true;
      this.isFulfilled = true;
      this.isRejected = false;
      return this._deferred.resolve(...arguments);
    }
    reject() {
      this.isPending = false;
      this.isSettled = true;
      this.isFulfilled = false;
      this.isRejected = true;
      return this._deferred.reject(...arguments);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isPending", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isSettled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_deferred", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "promise", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fullname", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fullname"), _class.prototype), _class);
});