define("@woody-lite/addon-crm-campaigns/components/notification-card", ["exports", "@ember/component", "@ember/template-factory", "@woody-lite/addon-crm-campaigns/components/crm-campaign", "@ember/object"], function (_exports, _component, _templateFactory, _crmCampaign, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @visible}}
  	<Cards::CardOffer
  		data-id={{concat "id_" @model.sourceName @model.sourcePriority}}
  		@align="left"
  		data-theme="secondary"
  		@image={{this.image}}
  		@title={{this.data.title}}
  		@text={{this.data.description}}
  		{{did-insert this.onDidInsert}}
  		as |card|
  	>
  		<card.button
  			data-id="bubbleButton"
  			@type="positive"
  			@text={{this.claim}}
  			@onClick={{this.sendOk}}
  		/>
  	</Cards::CardOffer>
  {{/if}}
  */
  {
    "id": "xIo2ZXBm",
    "block": "[[[41,[30,1],[[[1,\"\\t\"],[8,[39,1],[[16,\"data-id\",[28,[37,2],[\"id_\",[30,2,[\"sourceName\"]],[30,2,[\"sourcePriority\"]]],null]],[24,\"data-theme\",\"secondary\"],[4,[38,3],[[30,0,[\"onDidInsert\"]]],null]],[[\"@align\",\"@image\",\"@title\",\"@text\"],[\"left\",[30,0,[\"image\"]],[30,0,[\"data\",\"title\"]],[30,0,[\"data\",\"description\"]]]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[30,3,[\"button\"]],[[24,\"data-id\",\"bubbleButton\"]],[[\"@type\",\"@text\",\"@onClick\"],[\"positive\",[30,0,[\"claim\"]],[30,0,[\"sendOk\"]]]],null],[1,\"\\n\\t\"]],[3]]]]],[1,\"\\n\"]],[]],null]],[\"@visible\",\"@model\",\"card\"],false,[\"if\",\"cards/card-offer\",\"concat\",\"did-insert\"]]",
    "moduleName": "@woody-lite/addon-crm-campaigns/components/notification-card.hbs",
    "isStrictMode": false
  });
  let NotificationCardComponent = _exports.default = (_class = class NotificationCardComponent extends _crmCampaign.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endIcon", 'icon-add');
    }
    get data() {
      return this.model;
    }
    get model() {
      return this.args.model;
    }
    get claim() {
      return (0, _object.get)(this.data, 'claim');
    }
    get processStartedActions() {
      return (0, _object.get)(this.data, 'actions').filter(a => (0, _object.get)(a, 'trigger') === 'processStarted');
    }
    async initNavigation(targetAction) {
      this.doActions('processStartedActions');
      await this.transitionTo(targetAction);
    }
    _getActionUrl(crmAction) {
      const url = crmAction.url;
      return {
        url,
        validUrls: []
      };
    }
    transitionTo(crmAction) {
      const urls = this._getActionUrl(crmAction);
      const {
        url
      } = urls;
      const data = crmAction.inAppScreenData || {};
      this.args.transitionTo(url, {
        queryParams: data
      });
    }
    sendOk() {
      this.doActions('okActions');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "sendOk", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendOk"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NotificationCardComponent);
});