define("@woody-lite/core-semaas/decorators/evented", ["exports", "@ember/object/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = evented;
  function evented(Target) {
    const klass = class extends Target {
      on(name, target, method) {
        (0, _events.addListener)(this, name, target, method);
        return this;
      }
      one(name, target, method) {
        (0, _events.addListener)(this, name, target, method, true);
        return this;
      }
      trigger(name) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        (0, _events.sendEvent)(this, name, args);
      }
      off(name, target, method) {
        (0, _events.removeListener)(this, name, target, method);
        return this;
      }
    };
    Object.defineProperty(klass, 'name', {
      value: Target.name
    });
    return klass;
  }
});