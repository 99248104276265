define("@woody-lite/core-traceability/utils/load-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadScript;
  function loadScript(src) {
    let element = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'body';
    if (document && document.querySelector("script[src=\"".concat(src, "\"]"))) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);
      document[element].appendChild(script);
    });
  }
});