define("@woody-lite/engine-set-up-account/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('index', {
      path: '/'
    });
    this.route('landing');
    this.route('rounding-up', function () {
      this.route('confirmation', {
        path: '/confirmation/:mode'
      });
    });
    this.route('minimum-balance', function () {
      this.route('confirmation', {
        path: '/confirmation/:mode'
      });
    });
    this.route('payroll-savings', function () {
      this.route('confirmation', {
        path: '/confirmation/:mode'
      });
    });
    this.route('maximum-balance', function () {
      this.route('confirmation', {
        path: '/confirmation/:mode'
      });
    });
  });
});