/* eslint-disable */
/* Do not modify script */
(function () {
	window.loadScript = (url, dt, snippetId) => {
		return new Promise(function (resolve, reject) {
			var cookie_value = null;
			try {
				cookie_value = document.cookie;
			} catch (e) {}
			if (cookie_value) {
				var re = new RegExp(`(?:^| )(LSESSION_${snippetId}=.[^;]+)`, 'i');
				var matches = null;
				var result = null;
				if (cookie_value.length > 0) {
					matches = cookie_value.match(re);
					// eslint-disable-next-line eqeqeq
					if (matches && matches.length == 2) {
						result = matches[1];
					}
				}
			}
			var s = document.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.onload = resolve;
			s.onerror = reject;
			var extra = [`dt=${dt}&r=` + Math.random()];
			if (result) {
				extra.push(result);
			}
			s.src = [url, extra.join('&')].join('?');
			document.getElementsByTagName('head')[0].appendChild(s);
		});
	};

	window.stlgval = (step, values) => {
		if (step === 0) {
			window.stlgvalValue = {
				p: {
					p: values?.puid_value,
				},
				u: { p: [values?.uid_value] },
				c: values?.csid_value,
			};
		}
		if (step === 2) {
			window.stlgvalValue = {
				c: values?.csid_value,
			};
		}
		if (step === 10) {
			window.stlgvalValue = {
				p: {
					a: {
						new_account: true,
						phone_number: values?.phone_number?.replace(/^00/, ''),
						email: values?.email,
						first_name: values?.first_name,
						last_name: values?.last_name,
					},
					p: values?.puid_value,
				},
				u: { p: [values?.uid_value] },
				c: values?.csid_value,
			};
		}
		if (step === 'login') {
			window.stlgvalValue = {
				p: {
					p: values?.puid_value,
				},
				u: { p: [values?.uid_value] },
				c: values?.csid_value,
			};
		}

		return window.stlgvalValue;
	};
})();
