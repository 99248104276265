define("@woody-lite/addon-feedback/components/modal-feedback", ["exports", "@ember/component", "@ember/template-factory", "@woody-lite/core-foundation/components/modal", "@woody-lite/core-foundation/utils/serialize-query-params", "@ember/object", "@ember/application", "@glimmer/tracking", "@woody-lite/core-foundation/utils/window-open", "@ember/service"], function (_exports, _component, _templateFactory, _modal, _serializeQueryParams, _object, _application, _tracking, _windowOpen, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <WrapperMain as |wrapper|>
  	<wrapper.header @type="modal-primary" as |header|>
  		<header.title @title={{t "label.feedbackHeader"}} />
  		<header.right as |i|>
  			<i.icon
  				data-id="btnCloseFeedback"
  				@class="icon-close"
  				@onClick={{this.closeModal}}
  				@accessibilityText={{t "label.close"}}
  			/>
  		</header.right>
  	</wrapper.header>
  
  	<wrapper.network />
  
  	<wrapper.content>
  		<article>
  			<IframeExternal
  				data-id="feedback-opinator"
  				@src={{this.url}}
  				@allowedDomains={{array "https://www4.opinator.com"}}
  				@class="vh"
  				@events={{hash
  					openModal=this.openModal
  					updateStar=this.updateStar
  					navigateToMarket=this.rateInMarket
  				}}
  			/>
  		</article>
  	</wrapper.content>
  </WrapperMain>
  */
  {
    "id": "Tro6RcBB",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\t\"],[8,[30,1,[\"header\"]],null,[[\"@type\"],[\"modal-primary\"]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[30,2,[\"title\"]],null,[[\"@title\"],[[28,[37,1],[\"label.feedbackHeader\"],null]]],null],[1,\"\\n\\t\\t\"],[8,[30,2,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,3,[\"icon\"]],[[24,\"data-id\",\"btnCloseFeedback\"]],[[\"@class\",\"@onClick\",\"@accessibilityText\"],[\"icon-close\",[30,0,[\"closeModal\"]],[28,[37,1],[\"label.close\"],null]]],null],[1,\"\\n\\t\\t\"]],[3]]]]],[1,\"\\n\\t\"]],[2]]]]],[1,\"\\n\\n\\t\"],[8,[30,1,[\"network\"]],null,null,null],[1,\"\\n\\n\\t\"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\"],[10,\"article\"],[12],[1,\"\\n\\t\\t\\t\"],[8,[39,2],[[24,\"data-id\",\"feedback-opinator\"]],[[\"@src\",\"@allowedDomains\",\"@class\",\"@events\"],[[30,0,[\"url\"]],[28,[37,3],[\"https://www4.opinator.com\"],null],\"vh\",[28,[37,4],null,[[\"openModal\",\"updateStar\",\"navigateToMarket\"],[[30,0,[\"openModal\"]],[30,0,[\"updateStar\"]],[30,0,[\"rateInMarket\"]]]]]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"wrapper\",\"header\",\"i\"],false,[\"wrapper-main\",\"t\",\"iframe-external\",\"array\",\"hash\"]]",
    "moduleName": "@woody-lite/addon-feedback/components/modal-feedback.hbs",
    "isStrictMode": false
  });
  let ModalFeedback = _exports.default = (_class = class ModalFeedback extends _modal.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _initializerDefineProperty(this, "slinky", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "currentStars", _descriptor5, this);
    }
    get url() {
      const data = this.model.options;
      return this.getOpinatorParams(data);
    }
    getOpinatorParams(data) {
      let url = "https:".concat(data.feedbackUrl);
      const params = {
        ...data
      };
      delete params.feedbackUrl;
      delete params.isIOS;
      delete params.feedbackLang;
      url = url.replace(/(lang=[a-z]{2,})/g, "lang=".concat(data.feedbackLang));
      const unionChar = '&';
      const serialezQueryParams = (0, _serializeQueryParams.serializeQueryParams)(params);
      url = "".concat(url).concat(unionChar).concat(serialezQueryParams);
      return url;
    }
    get marketURL() {
      const data = this.model.options;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return data.isIOS ? config.APP.markets.apple : config.APP.markets.google;
    }
    rateInMarket() {
      return (0, _windowOpen.windowOpen)(this.marketURL, this);
    }
    openModal(message) {
      this.modal.open('iframe', {
        src: message.data
      });
    }
    updateStar(message) {
      this.currentStars = message.data[0];
    }
    closeModal() {
      this.close(this.currentStars);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentStars", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "rateInMarket", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "rateInMarket"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStar", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateStar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalFeedback);
});