define("@woody-lite/addon-utils/utils/copy-to-clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.copyToClipBoard = copyToClipBoard;
  function copyToClipBoard(text) {
    if (!text) {
      return false;
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.setAttribute('readOnly', true);
    textarea.select();
    document.execCommand('SelectAll');
    document.execCommand('Copy');
    textarea.blur();
    document.body.removeChild(textarea);
    return true;
  }
});