define("@woody-lite/addon-terms-and-conditions/repositories/terms-and-conditions", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/repositories/repository", "@ember/service", "@ember/object", "@woody-lite/core-session/decorators/session", "@ember/application", "@glimmer/tracking"], function (_exports, _repository, _service, _object, _session, _application, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const TERMS_UNIQ_ID = 'T&C';
  const DOCUMENT_UNIQ_ID = "".concat(TERMS_UNIQ_ID, "-DOC");
  let TermsAndConditionsRepository = _exports.default = (_dec = (0, _session.default)('client'), _class = class TermsAndConditionsRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "veil", _descriptor2, this);
      _initializerDefineProperty(this, "gam", _descriptor3, this);
      _initializerDefineProperty(this, "provider", _descriptor4, this);
      _initializerDefineProperty(this, "currentSession", _descriptor5, this);
      _initializerDefineProperty(this, "nonFinancialProductId", _descriptor6, this);
      this.nonFinancialProductId = (0, _application.getOwner)(this).resolveRegistration('config:environment')['engine-terms-and-conditions'].nonFinancialProductId;
    }
    getTermsAndConditions() {
      return this.createEntry('terms-and-conditions/terms-and-conditions', TERMS_UNIQ_ID, {
        documentId: this.nonFinancialProductId
      });
    }
    async _staticDocument() {
      const tycNode = await this._getTermsAndConditionsTexts();
      const urlDocument = tycNode.urlDocument;
      return this.createEntry('terms-and-conditions/document', "".concat(DOCUMENT_UNIQ_ID, "_staticDocument"), {
        urlDocument
      });
    }
    async _getDocument() {
      this.veil.add();
      const nonFinancialProductId = (0, _application.getOwner)(this).resolveRegistration('config:environment')['engine-terms-and-conditions'].nonFinancialProductId;
      let response = null;
      try {
        response = await this.enax.POST('validate-non-financial-service', null, {
          evidenceId: nonFinancialProductId
        });
      } catch {
        // Noop
      } finally {
        this.veil.remove();
      }
      const tycNode = await this._getTermsAndConditionsTexts();
      const urlDocument = tycNode.urlDocument;
      const data = response;
      return this.createEntry('terms-and-conditions/document', DOCUMENT_UNIQ_ID, {
        title: tycNode.title,
        legalConditions: tycNode.legalConditions,
        text: tycNode.content,
        urlDocument,
        ...data
      });
    }
    async _getTermsAndConditionsTexts() {
      const customer = await this.provider.pull('customer', 'getContextualData');
      const application = await this.provider.pull('application', 'getApplication');
      const response = await this.gam.POST('private-config', {}, {
        params: {
          expand: 'tycNode'
        },
        session: this.currentSession,
        customer,
        previousAppVersion: (0, _object.get)(application, 'previousVersion')
      });
      const data = response.data;
      const tycNode = data.tycNode;
      return tycNode;
    }
    _signDocument(termsDocument) {
      return this.enax.POST('non-financial-services', {
        nonFinancialProduct: (0, _object.get)(termsDocument, 'nonFinancialProduct'),
        targetUserId: this.currentSession.id
      }, {
        headers: {
          cuf: (0, _object.get)(termsDocument, 'cuf')
        }
      });
    }
    _clearDocument(termsDocument) {
      const {
        id,
        version
      } = (0, _object.get)(termsDocument, 'nonFinancialProduct');
      return this.enax.POST('unsubscribe-non-financial-service', null, {
        headers: {
          cuf: (0, _object.get)(termsDocument, 'cuf')
        },
        evidenceId: id,
        version,
        customerId: this.currentSession.id,
        reason: '3'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "gam", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nonFinancialProductId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});