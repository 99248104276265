define("@woody-lite/addon-feedback/decorators/fetch-opinator-configuration", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fetchOpinatorConfiguration;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function fetchOpinatorConfiguration(Target) {
    var _class, _descriptor;
    const Klass = (_class = class FetchOpinatorConfiguration extends Target {
      constructor() {
        super(...arguments);
        _initializerDefineProperty(this, "opinatorConfiguration", _descriptor, this);
      }
      async didTransition() {
        if (super.didTransition) {
          await super.didTransition(...arguments);
        }
        try {
          const link = await this.opinatorConfiguration.fetch(this.fullRouteName, this.feedbackFormId, this.feedbackType);
          this.feedbackUrl = link;
        } catch {
          // Noop
        }
      }
    }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "opinatorConfiguration", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype), _class);
    Object.defineProperty(Klass, 'name', {
      value: Target.name,
      writable: true,
      configurable: true
    });
    return Klass;
  }
});