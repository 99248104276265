define("@woody-lite/core-traceability/events/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BaseTealiumEvent {
    constructor(type) {
      this.type = type;
    }
  }
  _exports.default = BaseTealiumEvent;
});