define("ember-alerter/components/alert-container", ["exports", "@ember/component", "ember-alerter/templates/components/alert-container", "@ember/array", "@ember/object", "@ember/service", "@ember/object/evented"], function (_exports, _component, _alertContainer, _array, _object, _service, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-on-calls-in-components */
  /**
   * Component that sorts alerts from service.
   *
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _alertContainer.default,
    /**
     * Service injection
     *
     * @property alerter
     * @type Object
     */
    alerter: (0, _service.inject)(),
    /**
     * HTML Classes.
     *
     * @property classNames
     * @type Array
     */
    classNames: ['alrtCont'],
    /**
     * Attribute bindings.
     *
     * @property attributeBindings
     * @type Array
     */
    attributeBindings: ['data-alert-show'],
    /**
     * Compute data-alert-show property.
     *
     * @property data-alert-show
     * @type Boolean
     */
    'data-alert-show': (0, _object.computed)('currentAlert.isShown', function () {
      const isShown = this.get('currentAlert.isShown');
      return String(isShown);
    }),
    /**
     * Filtered content of the component by view elementId.
     *
     * @property content
     * @type Array
     */
    content: (0, _object.computed)('alerter.content.[]', function () {
      const elementId = this.get('elementId');
      const filter = this.get('alerter.content').filter(alert => {
        const view = alert.get('view');

        // Select alerts that has no view in every container or
        // the one that has the current view.
        return !view || elementId === view;
      });
      return (0, _array.A)(filter).sortBy('isPermanent', 'duration');
    }),
    /**
     * Get current alert as the first object or the current object showing.
     *
     * @property currentAlert
     * @type Object
     */
    currentAlert: (0, _object.computed)('content.[]', function () {
      let currentAlert = this.get('content');
      currentAlert = (0, _array.A)(currentAlert).rejectBy('isPermanent', true);
      currentAlert = (0, _array.A)(currentAlert).findBy('isShown', true);
      if (!currentAlert) {
        currentAlert = this.get('content.firstObject');
      }
      return currentAlert;
    }),
    /**
     * Register the view in the alerter service.
     *
     * @method register
     */
    register: (0, _evented.on)('willInsertElement', function () {
      this.get('alerter.views').addObject(this);
    }),
    /**
     * Unregister the view in the alerter service.
     *
     * @method unregister
     */
    unregister: (0, _evented.on)('willDestroyElement', function () {
      this.get('alerter.views').removeObject(this);
    })
  });
});