define("@woody-lite/addon-conversations/models/thread-message-attachment", ["exports", "@woody-mrs-potato/addon-ember-object-data-model/models/base", "@ember/service", "@ember/object"], function (_exports, _base, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const ICONS = {
    jpeg: 'image',
    jpg: 'image',
    png: 'image',
    gif: 'image',
    bmp: 'image',
    avi: 'video',
    mp4: 'video',
    wav: 'audio',
    mp3: 'audio',
    mpeg3: 'audio',
    ppt: 'presentation',
    pptx: 'presentation',
    xls: 'excel',
    xlsx: 'excel',
    pdf: 'pdf',
    txt: 'doc',
    doc: 'doc',
    docx: 'doc',
    default: 'doc'
  };
  let ThreadMessageAttachmentModel = _exports.default = (_class = class ThreadMessageAttachmentModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "file", _descriptor2, this);
      _initializerDefineProperty(this, "veil", _descriptor3, this);
    }
    get displayableName() {
      return "".concat((0, _object.get)(this, 'name'), ".").concat((0, _object.get)(this, 'extension'));
    }
    get icon() {
      return ICONS[(0, _object.get)(this, 'extension')] || ICONS.default;
    }
    async open() {
      try {
        this.veil.add();
        const response = await this.enax.GET('get-document', {
          document: (0, _object.get)(this, 'fileUrl.href')
        });
        const documentDownloaded = (0, _object.get)(response || {}, 'attachmentPart.response');
        if (documentDownloaded) {
          this.veil.remove();
          await this.file.show(documentDownloaded, {
            contentType: null,
            name: documentDownloaded.name
          });
        }
      } catch {
        // Noop
      } finally {
        this.veil.remove();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "file", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "open", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _class);
});