define("@woody-lite/core-session/normalizers/customer-logins", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const personTypeEquivalences = {
    NATURAL: 'F',
    LEGAL: 'J'
  };
  const documentEquivalences = {
    NIF: '1',
    COMPANY_DOCUMENT: '2',
    FOREIGN_COMPANY_DOCUMENT: '3',
    DNI_UNDER_EIGHTEEN: '5',
    FOREIGNERS: '6',
    PASSPORT: '7',
    DOCUMENT_ADD_IN_OFFICE: '8',
    COMPANY_INTERNATIONAL_DOCUMENT: '9',
    NIF_OTHER_COUNTRY: 'B'
  };
  const sexEquivalences = {
    MALE: 'V',
    FEMALE: 'M'
  };
  const customerContactTypeEquivalences = {
    MOBILE: 'CELLPHONE',
    EMAIL: 'MAIL'
  };
  const managerContactTypeEquivalences = {
    MOBILE: 'telefonoGestor',
    EMAIL: 'email',
    LANDLINE: 'telefonoGeneral'
  };
  const normativeStatusEquivalences = {
    PENDING_CUSTOMER: 'PE',
    BLOCKED: 'BL',
    CANCELLED: 'CA',
    PENDING_BANK: 'PV',
    CEASE_RELATIONS: 'CE',
    PROCESS_OF_BLOCKED: 'PB'
  };
  const normativeActionEquivalences = {
    ADD_DATA: 'AD',
    SIGN_AND_UPLOAD_DOCS: 'CF',
    LOGIN_ADD_DATA: 'LA',
    LOGIN_SIGN_AND_UPLOAD_DOCS: 'LC',
    NOT_NECCESARY: 'NA'
  };
  const normativeConsequenceEquivalences = {
    NOTIFIED: 'AC',
    CONTRACTION_BLOCKED: 'BC',
    LOGIN_BLOCKED: 'BL',
    OPERATIVE_BLOCKED: 'OB'
  };
  const GLOBAL_SEGMENT = 'GB';
  class CustomerLoginsNormalizer extends _object.default {
    getCustomer(item) {
      var _item$birthData, _item$bank, _item$bank$branch, _this$getIndicator, _this$getIndicator$re, _this$getIndicator$re2, _this$getIndicator2, _this$getIndicator2$r, _this$getIndicator2$r2, _item$segments, _this$getIndicator3, _this$getIndicator4, _this$getIndicator4$r, _this$getIndicator4$r2, _this$getIndicator5, _this$getIndicator5$r, _this$getIndicator5$r2, _item$collectives, _item$contactDetails, _item$identificationS, _item$economicData, _item$economicData$oc, _item$bankRelationAff, _item$gender, _item$language, _item$personalTitle, _item$personalTitle2, _item$identityDocumen, _item$identityDocumen2, _item$identityDocumen3, _item$fullName, _item$personType, _item$fullName2, _item$fullName3, _item$normatives, _this$getIndicator11, _this$getIndicator12, _item$identificationS2, _item$fullName4, _this$getIndicator13, _this$getIndicator14, _this$getIndicator14$, _this$getIndicator14$2, _this$getIndicator15, _this$getIndicator16, _this$getIndicator16$, _this$getIndicator16$2, _this$getIndicator17, _this$getIndicator17$, _this$getIndicator17$2, _this$getIndicator18, _this$getIndicator19, _this$getIndicator20, _this$getIndicator21, _this$getIndicator22, _item$personType2;
      return {
        birthDate: (_item$birthData = item.birthData) === null || _item$birthData === void 0 ? void 0 : _item$birthData.birthDate,
        branch: {
          id: (_item$bank = item.bank) === null || _item$bank === void 0 ? void 0 : (_item$bank$branch = _item$bank.branch) === null || _item$bank$branch === void 0 ? void 0 : _item$bank$branch.id
        },
        classification: {
          highValueCustomer: {
            id: (_this$getIndicator = this.getIndicator(item, 'HIGH_VALUE_CUSTOMER')) === null || _this$getIndicator === void 0 ? void 0 : (_this$getIndicator$re = _this$getIndicator.reasons) === null || _this$getIndicator$re === void 0 ? void 0 : (_this$getIndicator$re2 = _this$getIndicator$re[0]) === null || _this$getIndicator$re2 === void 0 ? void 0 : _this$getIndicator$re2.id,
            name: (_this$getIndicator2 = this.getIndicator(item, 'HIGH_VALUE_CUSTOMER')) === null || _this$getIndicator2 === void 0 ? void 0 : (_this$getIndicator2$r = _this$getIndicator2.reasons) === null || _this$getIndicator2$r === void 0 ? void 0 : (_this$getIndicator2$r2 = _this$getIndicator2$r[0]) === null || _this$getIndicator2$r2 === void 0 ? void 0 : _this$getIndicator2$r2.description
          },
          segments: (_item$segments = item.segments) === null || _item$segments === void 0 ? void 0 : _item$segments.map(segment => {
            return {
              name: segment.id,
              value: segment.value
            };
          }),
          isVIP: (_this$getIndicator3 = this.getIndicator(item, 'VIP')) === null || _this$getIndicator3 === void 0 ? void 0 : _this$getIndicator3.isActive,
          endOfRelationship: {
            id: (_this$getIndicator4 = this.getIndicator(item, 'CEASE_RELATIONS')) === null || _this$getIndicator4 === void 0 ? void 0 : (_this$getIndicator4$r = _this$getIndicator4.reasons) === null || _this$getIndicator4$r === void 0 ? void 0 : (_this$getIndicator4$r2 = _this$getIndicator4$r[0]) === null || _this$getIndicator4$r2 === void 0 ? void 0 : _this$getIndicator4$r2.id,
            name: (_this$getIndicator5 = this.getIndicator(item, 'CEASE_RELATIONS')) === null || _this$getIndicator5 === void 0 ? void 0 : (_this$getIndicator5$r = _this$getIndicator5.reasons) === null || _this$getIndicator5$r === void 0 ? void 0 : (_this$getIndicator5$r2 = _this$getIndicator5$r[0]) === null || _this$getIndicator5$r2 === void 0 ? void 0 : _this$getIndicator5$r2.description
          }
        },
        collectives: (_item$collectives = item.collectives) === null || _item$collectives === void 0 ? void 0 : _item$collectives.map(collective => {
          let isPreferential = '';
          if ('isPreferential' in collective) {
            isPreferential = collective.isPreferential ? 'S' : 'N';
          }
          return {
            id: collective.id,
            version: collective.description,
            isPreferential
          };
        }),
        contactsInformation: (_item$contactDetails = item.contactDetails) === null || _item$contactDetails === void 0 ? void 0 : _item$contactDetails.map(contactDetail => {
          var _contactDetail$contac2, _contactDetail$contac3, _contactDetail$contac4, _contactDetail$contac5, _contactDetail$contac6;
          let isValid = '';
          if ('isValid' in (contactDetail === null || contactDetail === void 0 ? void 0 : contactDetail.contact)) {
            var _contactDetail$contac;
            isValid = (_contactDetail$contac = contactDetail.contact) !== null && _contactDetail$contac !== void 0 && _contactDetail$contac.isValid ? 'V' : 'N';
          }
          return {
            type: {
              id: contactDetail.id,
              name: customerContactTypeEquivalences[(_contactDetail$contac2 = contactDetail.contact) === null || _contactDetail$contac2 === void 0 ? void 0 : _contactDetail$contac2.contactDetailType] || ''
            },
            name: ((_contactDetail$contac3 = contactDetail.contact) === null || _contactDetail$contac3 === void 0 ? void 0 : _contactDetail$contac3.contactDetailType) === 'EMAIL' ? (_contactDetail$contac4 = contactDetail.contact) === null || _contactDetail$contac4 === void 0 ? void 0 : _contactDetail$contac4.address : (_contactDetail$contac5 = contactDetail.contact) === null || _contactDetail$contac5 === void 0 ? void 0 : _contactDetail$contac5.number,
            checked: (_contactDetail$contac6 = contactDetail.contact) === null || _contactDetail$contac6 === void 0 ? void 0 : _contactDetail$contac6.isVerified,
            status: isValid
          };
        }),
        customerIdentifDate: (_item$identificationS = item.identificationStatus) === null || _item$identificationS === void 0 ? void 0 : _item$identificationS.date,
        economicData: (_item$economicData = item.economicData) === null || _item$economicData === void 0 ? void 0 : (_item$economicData$oc = _item$economicData.occupationType) === null || _item$economicData$oc === void 0 ? void 0 : _item$economicData$oc.id,
        employeeData: {
          isEmployee: ((_item$bankRelationAff = item.bankRelationAffiliation) === null || _item$bankRelationAff === void 0 ? void 0 : _item$bankRelationAff.id) === 'EMPLOYEE'
        },
        extendedData: {
          sex: sexEquivalences[(_item$gender = item.gender) === null || _item$gender === void 0 ? void 0 : _item$gender.id],
          language: {
            id: (_item$language = item.language) === null || _item$language === void 0 ? void 0 : _item$language.id
          },
          prefix: {
            id: (_item$personalTitle = item.personalTitle) === null || _item$personalTitle === void 0 ? void 0 : _item$personalTitle.id,
            name: (_item$personalTitle2 = item.personalTitle) === null || _item$personalTitle2 === void 0 ? void 0 : _item$personalTitle2.description
          }
        },
        identityDocument: [{
          type: {
            id: documentEquivalences[(_item$identityDocumen = item.identityDocument) === null || _item$identityDocumen === void 0 ? void 0 : (_item$identityDocumen2 = _item$identityDocumen.documentType) === null || _item$identityDocumen2 === void 0 ? void 0 : _item$identityDocumen2.id]
          },
          number: (_item$identityDocumen3 = item.identityDocument) === null || _item$identityDocumen3 === void 0 ? void 0 : _item$identityDocumen3.documentNumber
        }],
        lastName: (_item$fullName = item.fullName) === null || _item$fullName === void 0 ? void 0 : _item$fullName.lastName,
        name: ((_item$personType = item.personType) === null || _item$personType === void 0 ? void 0 : _item$personType.id) === 'NATURAL' ? (_item$fullName2 = item.fullName) === null || _item$fullName2 === void 0 ? void 0 : _item$fullName2.firstName : (_item$fullName3 = item.fullName) === null || _item$fullName3 === void 0 ? void 0 : _item$fullName3.legalName,
        normatives: item === null || item === void 0 ? void 0 : (_item$normatives = item.normatives) === null || _item$normatives === void 0 ? void 0 : _item$normatives.map(normative => {
          var _normative$status, _normative$status2, _this$getIndicator6, _this$getIndicator6$r, _this$getIndicator6$r2, _this$getIndicator7, _this$getIndicator8, _this$getIndicator9, _this$getIndicator10, _normative$actions;
          return {
            id: normative.id,
            name: normative.name,
            status: normativeStatusEquivalences[(_normative$status = normative.status) === null || _normative$status === void 0 ? void 0 : _normative$status.id],
            statusDate: (_normative$status2 = normative.status) === null || _normative$status2 === void 0 ? void 0 : _normative$status2.date,
            pendingDocument: (_this$getIndicator6 = this.getIndicator(normative, 'NEEDS_DELIVERY')) === null || _this$getIndicator6 === void 0 ? void 0 : (_this$getIndicator6$r = _this$getIndicator6.reasons) === null || _this$getIndicator6$r === void 0 ? void 0 : (_this$getIndicator6$r2 = _this$getIndicator6$r[0]) === null || _this$getIndicator6$r2 === void 0 ? void 0 : _this$getIndicator6$r2.id,
            isUpdateDocument: (_this$getIndicator7 = this.getIndicator(normative, 'NEEDS_UPDATES')) === null || _this$getIndicator7 === void 0 ? void 0 : _this$getIndicator7.isActive,
            isCurrentDocument: (_this$getIndicator8 = this.getIndicator(normative, 'VALID_DOCUMENT')) === null || _this$getIndicator8 === void 0 ? void 0 : _this$getIndicator8.isActive,
            isNearBlockage: (_this$getIndicator9 = this.getIndicator(normative, 'NEAR_BLOCKAGE')) === null || _this$getIndicator9 === void 0 ? void 0 : _this$getIndicator9.isActive,
            userBlockDate: (_this$getIndicator10 = this.getIndicator(normative, 'NEAR_BLOCKAGE')) === null || _this$getIndicator10 === void 0 ? void 0 : _this$getIndicator10.blockingDate,
            channels: [{
              actions: (_normative$actions = normative.actions) === null || _normative$actions === void 0 ? void 0 : _normative$actions.map(action => {
                var _action$consequences;
                return {
                  id: normativeActionEquivalences[action.id],
                  name: action.name,
                  consequences: (_action$consequences = action.consequences) === null || _action$consequences === void 0 ? void 0 : _action$consequences.map(consequence => {
                    return {
                      id: normativeConsequenceEquivalences[consequence.id],
                      isResolve: consequence.isResolved,
                      name: consequence.name
                    };
                  })
                };
              })
            }]
          };
        }),
        managementData: {
          hasFatcaCrsOperativeBlockage: (_this$getIndicator11 = this.getIndicator(item, 'FATCA_LOCKED')) === null || _this$getIndicator11 === void 0 ? void 0 : _this$getIndicator11.isActive,
          hasRbaOperativeBlockage: (_this$getIndicator12 = this.getIndicator(item, 'RBA_LOCKED')) === null || _this$getIndicator12 === void 0 ? void 0 : _this$getIndicator12.isActive,
          IsIdentified: (_item$identificationS2 = item.identificationStatus) === null || _item$identificationS2 === void 0 ? void 0 : _item$identificationS2.isIdentified
        },
        mothersLastName: (_item$fullName4 = item.fullName) === null || _item$fullName4 === void 0 ? void 0 : _item$fullName4.secondLastName,
        multichannelPassport: {
          signed: {
            id: (_this$getIndicator13 = this.getIndicator(item, 'MULTICHANNEL_CONTRACT')) !== null && _this$getIndicator13 !== void 0 && _this$getIndicator13.isActive ? 'S' : 'N'
          },
          attachedDocumentSigned: {
            id: (_this$getIndicator14 = this.getIndicator(item, 'MULTICHANNEL_CONTRACT')) === null || _this$getIndicator14 === void 0 ? void 0 : (_this$getIndicator14$ = _this$getIndicator14.reasons) === null || _this$getIndicator14$ === void 0 ? void 0 : (_this$getIndicator14$2 = _this$getIndicator14$[0]) === null || _this$getIndicator14$2 === void 0 ? void 0 : _this$getIndicator14$2.id
          }
        },
        productMarks: {
          hasFinancedTransactions: (_this$getIndicator15 = this.getIndicator(item, 'FUNDABLE_TRANSACTIONS')) === null || _this$getIndicator15 === void 0 ? void 0 : _this$getIndicator15.isActive,
          hasPreGrantedLoan: {
            id: (_this$getIndicator16 = this.getIndicator(item, 'LOAN_PREGRANTED')) === null || _this$getIndicator16 === void 0 ? void 0 : (_this$getIndicator16$ = _this$getIndicator16.reasons) === null || _this$getIndicator16$ === void 0 ? void 0 : (_this$getIndicator16$2 = _this$getIndicator16$[0]) === null || _this$getIndicator16$2 === void 0 ? void 0 : _this$getIndicator16$2.id,
            name: (_this$getIndicator17 = this.getIndicator(item, 'LOAN_PREGRANTED')) === null || _this$getIndicator17 === void 0 ? void 0 : (_this$getIndicator17$ = _this$getIndicator17.reasons) === null || _this$getIndicator17$ === void 0 ? void 0 : (_this$getIndicator17$2 = _this$getIndicator17$[0]) === null || _this$getIndicator17$2 === void 0 ? void 0 : _this$getIndicator17$2.description
          },
          hasPendingTasks: (_this$getIndicator18 = this.getIndicator(item, 'PENDING_TASKS')) === null || _this$getIndicator18 === void 0 ? void 0 : _this$getIndicator18.isActive,
          hasIICs: (_this$getIndicator19 = this.getIndicator(item, 'SICAVS')) === null || _this$getIndicator19 === void 0 ? void 0 : _this$getIndicator19.isActive,
          hasCRMCampaigns: (_this$getIndicator20 = this.getIndicator(item, 'CRM_CAMPAIGN')) === null || _this$getIndicator20 === void 0 ? void 0 : _this$getIndicator20.isActive,
          hasPendingPurchase: (_this$getIndicator21 = this.getIndicator(item, 'PENDING_PURCHASES')) === null || _this$getIndicator21 === void 0 ? void 0 : _this$getIndicator21.isActive,
          hasReturnedCorrespondence: (_this$getIndicator22 = this.getIndicator(item, 'RETURN_CORRESPONDENCE')) === null || _this$getIndicator22 === void 0 ? void 0 : _this$getIndicator22.isActive
        },
        type: {
          id: personTypeEquivalences[(_item$personType2 = item.personType) === null || _item$personType2 === void 0 ? void 0 : _item$personType2.id]
        }
      };
    }
    getManager(item) {
      var _item$businessAgent, _item$businessAgent2, _item$businessAgent2$, _item$businessAgent3, _item$businessAgent4, _item$businessAgent5, _item$businessAgent6, _item$businessAgent7, _item$businessAgent7$, _item$businessAgent8, _item$businessAgent8$, _item$businessAgent9, _item$businessAgent9$, _item$businessAgent10, _item$businessAgent11, _item$businessAgent12, _item$segments2, _item$segments2$find;
      return {
        id: (_item$businessAgent = item.businessAgent) === null || _item$businessAgent === void 0 ? void 0 : _item$businessAgent.id,
        type: {
          id: (_item$businessAgent2 = item.businessAgent) === null || _item$businessAgent2 === void 0 ? void 0 : (_item$businessAgent2$ = _item$businessAgent2.portfolioUnallocation) === null || _item$businessAgent2$ === void 0 ? void 0 : _item$businessAgent2$.id
        },
        branch: {
          id: (_item$businessAgent3 = item.businessAgent) === null || _item$businessAgent3 === void 0 ? void 0 : _item$businessAgent3.bank
        },
        position: (_item$businessAgent4 = item.businessAgent) === null || _item$businessAgent4 === void 0 ? void 0 : _item$businessAgent4.position,
        rank: (_item$businessAgent5 = item.businessAgent) === null || _item$businessAgent5 === void 0 ? void 0 : _item$businessAgent5.rank,
        userCode: (_item$businessAgent6 = item.businessAgent) === null || _item$businessAgent6 === void 0 ? void 0 : _item$businessAgent6.userCode,
        portfolioUnallocationType: (_item$businessAgent7 = item.businessAgent) === null || _item$businessAgent7 === void 0 ? void 0 : (_item$businessAgent7$ = _item$businessAgent7.porfolio) === null || _item$businessAgent7$ === void 0 ? void 0 : _item$businessAgent7$.id,
        allocationDate: (_item$businessAgent8 = item.businessAgent) === null || _item$businessAgent8 === void 0 ? void 0 : (_item$businessAgent8$ = _item$businessAgent8.allocationDate) === null || _item$businessAgent8$ === void 0 ? void 0 : _item$businessAgent8$.date,
        contactInformation: (_item$businessAgent9 = item.businessAgent) === null || _item$businessAgent9 === void 0 ? void 0 : (_item$businessAgent9$ = _item$businessAgent9.contactDetails) === null || _item$businessAgent9$ === void 0 ? void 0 : _item$businessAgent9$.map(contactDetail => {
          var _contactDetail$contac7, _contactDetail$contac8, _contactDetail$contac9, _contactDetail$contac10, _contactDetail$contac11, _contactDetail$contac12;
          return {
            type: {
              id: managerContactTypeEquivalences[!((_contactDetail$contac7 = contactDetail.contact) !== null && _contactDetail$contac7 !== void 0 && _contactDetail$contac7.isGeneral) && ((_contactDetail$contac8 = contactDetail.contact) === null || _contactDetail$contac8 === void 0 ? void 0 : _contactDetail$contac8.contactDetailType) === 'LANDLINE' ? 'MOBILE' : (_contactDetail$contac9 = contactDetail.contact) === null || _contactDetail$contac9 === void 0 ? void 0 : _contactDetail$contac9.contactDetailType] || ''
            },
            name: ((_contactDetail$contac10 = contactDetail.contact) === null || _contactDetail$contac10 === void 0 ? void 0 : _contactDetail$contac10.contactDetailType) === 'EMAIL' ? (_contactDetail$contac11 = contactDetail.contact) === null || _contactDetail$contac11 === void 0 ? void 0 : _contactDetail$contac11.address : (_contactDetail$contac12 = contactDetail.contact) === null || _contactDetail$contac12 === void 0 ? void 0 : _contactDetail$contac12.number
          };
        }),
        name: (_item$businessAgent10 = item.businessAgent) === null || _item$businessAgent10 === void 0 ? void 0 : _item$businessAgent10.firstName,
        lastName: (_item$businessAgent11 = item.businessAgent) === null || _item$businessAgent11 === void 0 ? void 0 : _item$businessAgent11.lastName,
        mothersLastName: (_item$businessAgent12 = item.businessAgent) === null || _item$businessAgent12 === void 0 ? void 0 : _item$businessAgent12.secondLastName,
        globalSegment: (_item$segments2 = item.segments) === null || _item$segments2 === void 0 ? void 0 : (_item$segments2$find = _item$segments2.find(segment => segment.id === GLOBAL_SEGMENT)) === null || _item$segments2$find === void 0 ? void 0 : _item$segments2$find.name
      };
    }
    getExternalPlatformUsers(item) {
      var _item$externalPlatfor;
      return (_item$externalPlatfor = item.externalPlatformUsers) === null || _item$externalPlatfor === void 0 ? void 0 : _item$externalPlatfor.map(user => {
        return {
          userExternalId: user.id,
          externalPlatformCode: user.externalChannel.id
        };
      });
    }
    getIndicator(item, indicatorId) {
      var _item$indicators;
      return (_item$indicators = item.indicators) === null || _item$indicators === void 0 ? void 0 : _item$indicators.find(indicator => indicator.id === indicatorId);
    }
    normalize(item) {
      return {
        customer: this.getCustomer(item),
        manager: this.getManager(item),
        externalPlatformUsers: this.getExternalPlatformUsers(item),
        isCustomerLogins: true
      };
    }
  }
  _exports.default = CustomerLoginsNormalizer;
});