define("@woody-lite/engine-cancel-insurance/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('index', {
      path: '/'
    });
    this.route('confirmation');
    this.route('error');
  });
});