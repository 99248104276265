define("liquid-fire/components/liquid-container", ["exports", "@ember/component", "liquid-fire/mixins/growable", "liquid-fire/components/liquid-measured", "liquid-fire/templates/components/liquid-container"], function (_exports, _component, _growable, _liquidMeasured, _liquidContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_growable.default, {
    layout: _liquidContainer.default,
    classNames: ['liquid-container'],
    lockSize(elt, want) {
      elt.style.width = "".concat(want.width, "px");
      elt.style.height = "".concat(want.height, "px");
    },
    unlockSize() {
      let doUnlock = () => {
        this.updateAnimatingClass(false);
        if (this.element) {
          this.element.style.width = '';
          this.element.style.height = '';
        }
      };
      if (this._scaling) {
        this._scaling.then(doUnlock);
      } else {
        doUnlock();
      }
    },
    // We're doing this manually instead of via classNameBindings
    // because it depends on upward-data-flow, which generates warnings
    // under Glimmer.
    updateAnimatingClass(on) {
      if (this.isDestroyed) {
        return;
      }
      if (on) {
        this.element.classList.add('liquid-animating');
      } else {
        this.element.classList.remove('liquid-animating');
      }
    },
    didInsertElement() {
      this._super(...arguments);
      this._wasInserted = true;
    },
    actions: {
      willTransition(versions) {
        if (!this._wasInserted) {
          return;
        }

        // Remember our own size before anything changes
        this._cachedSize = (0, _liquidMeasured.measure)(this.element);

        // And make any children absolutely positioned with fixed sizes.
        for (let i = 0; i < versions.length; i++) {
          goAbsolute(versions[i]);
        }
      },
      afterChildInsertion(versions) {
        let elt = this.element;
        let enableGrowth = this.enableGrowth !== false;

        // Measure children
        let sizes = [];
        for (let i = 0; i < versions.length; i++) {
          if (versions[i].view) {
            sizes[i] = (0, _liquidMeasured.measure)(versions[i].view.element);
          }
        }

        // Measure ourself again to see how big the new children make
        // us.
        let want = (0, _liquidMeasured.measure)(elt);
        let have = this._cachedSize || want;

        // Make ourself absolute
        if (enableGrowth) {
          this.lockSize(elt, have);
        } else {
          this.lockSize(elt, {
            height: Math.max(want.height, have.height),
            width: Math.max(want.width, have.width)
          });
        }

        // Apply '.liquid-animating' to liquid-container allowing
        // any customizable CSS control while an animating is occuring
        this.updateAnimatingClass(true);

        // Make the children absolute and fixed size.
        for (let i = 0; i < versions.length; i++) {
          goAbsolute(versions[i], sizes[i]);
        }

        // Kick off our growth animation
        if (enableGrowth) {
          this._scaling = this.animateGrowth(elt, have, want);
        }
      },
      afterTransition(versions) {
        for (let i = 0; i < versions.length; i++) {
          goStatic(versions[i]);
        }
        this.unlockSize();
      }
    }
  });
  function goAbsolute(version, size) {
    if (!version.view) {
      return;
    }
    let elt = version.view.element;
    let pos = {
      top: elt.offsetTop,
      left: elt.offsetLeft
    };
    if (!size) {
      size = (0, _liquidMeasured.measure)(elt);
    }
    elt.style.width = "".concat(size.width, "px");
    elt.style.height = "".concat(size.height, "px");
    elt.style.position = 'absolute';
    elt.style.top = "".concat(pos.top, "px");
    elt.style.left = "".concat(pos.left, "px");
  }
  function goStatic(version) {
    if (version.view && !version.view.isDestroyed) {
      let elt = version.view.element;
      elt.style.width = '';
      elt.style.height = '';
      elt.style.position = '';
    }
  }
});