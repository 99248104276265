define("@woody-mrs-potato/addon-utils/helpers/runloop/next", ["exports", "@ember/component/helper", "@ember/runloop"], function (_exports, _helper, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function runloopNext(_ref /* , named*/) {
    let [method, ...headArgs] = _ref;
    return function () {
      for (var _len = arguments.length, tailArgs = new Array(_len), _key = 0; _key < _len; _key++) {
        tailArgs[_key] = arguments[_key];
      }
      (0, _runloop.next)(null, method, ...headArgs, ...tailArgs);
    };
  });
});