define("@woody-lite/core-app-shell/instance-initializers/app-shell", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // inspired from:
  // https://github.com/ember-fastboot/ember-cli-fastboot/blob/e05d1a70c3b700102d1a2259b2fafda464773848/app/instance-initializers/clear-double-boot.js
  const MAX_WAIT_TIME = 300;
  const APP_SHELL_SELECTOR = '[data-id="app-shell"]';
  function removeAppShell(element) {
    window.cleanErrorView();
    element.addEventListener('animationend', () => window.cleanAppShell());
    element.classList.add('destroy');
  }
  function initialize(appInstance) {
    if (typeof FastBoot === 'undefined') {
      const originalDidCreateRootView = appInstance.didCreateRootView;
      appInstance.didCreateRootView = function () {
        const appShell = document.querySelector(APP_SHELL_SELECTOR);
        if (appShell) {
          (0, _runloop.later)(() => removeAppShell(appShell), MAX_WAIT_TIME);
        }
        originalDidCreateRootView.apply(appInstance, arguments);
      };
    }
  }
  var _default = _exports.default = {
    name: 'app-shell',
    initialize
  };
});